import { PercentageDirective } from "./percentage.directive";
import { TamanhoDirective } from "./tamanho.directive";
import { PermissaoDirective } from "./permissao.directive";
import { PostFormDirective } from "./post.form.directive";
import { FocusDirective } from "./focus.directive";
import { TabIndexSelectDirective } from "./tabindex.directive";
import { UniqueLoginValidatorDirective } from "./unique.login.directive";
import { CopyClipboardDirective } from "./copy-clipboard.directive";
import { RemoveSpecialCharacterDirective } from "./remove-special-character";
import { OnlyNumberDirective } from "./only-number";

export const Directives = [
  TamanhoDirective,
  PercentageDirective,
  PermissaoDirective,
  PostFormDirective,
  FocusDirective,
  TabIndexSelectDirective,
  UniqueLoginValidatorDirective,
  CopyClipboardDirective,
  RemoveSpecialCharacterDirective,
  OnlyNumberDirective
];
