import { ActivatedRoute, Router } from "@angular/router";
import { Component, Output, EventEmitter, Input } from "@angular/core";

import { Lista } from "../../../common/model/lista";
import { EstrategiaTipo } from "../../../common/model/estrategia-tipo";
import { CarteiraService } from "../../../common/service/carteira.service";
import { ListaService } from "../../../common/service/lista.service";
import { FiltroLista } from "../../../common/model/filtro-lista";
import { Carteira } from "../../../common/model/carteira";

@Component({
	selector: "app-filtro-estrategia",
	templateUrl: "./filtro-estrategia.component.html",
	providers: [
		{ provide: CarteiraService, useClass: CarteiraService },
		{ provide: ListaService, useClass: ListaService }
	]
})
export class FiltroEstrategiaComponent {
	listas: Array<Lista>;
	carteiras: Array<Carteira> = [];
	recorrencias: Array<any>;

	@Input()
	filtro: FiltroLista;
	@Input()
	travarEscolhaLista;
	@Output()
	filtrar: EventEmitter<any>;

	public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1);
	}

	constructor(private listaService: ListaService, private carteiraService: CarteiraService, private route: ActivatedRoute, private router: Router) {
		this.filtrar = new EventEmitter();
		this.recorrencias = this.listaService.obterEstrategiaRecorrencia();

		let filtroLista = new FiltroLista();
		filtroLista.ignorarErroPesquisa = true;


		//	this.filtro.carteiraId = (params.listaId != undefined) ? { lista: parseInt(params.listaId) } : {};

		this.route.params.subscribe(params => {
			if (params.listaId != undefined) {
				this.listaService.obterListaSemQuantidade().subscribe((listas: Array<Lista>) => {
					let lista = listas.find(f => f.listaId == params.listaId);
					this.filtro.carteiraId = (lista != null) ? lista.carteiraId : 1;
					this.obterCarteiras(false);
				});
			} else {
				this.obterCarteiras();
			}
		});
	}

	obterCarteiras(setCarteiraInicial: boolean = true) {
		this.carteiraService.obterCarteiras().subscribe(carteiras => {
				this.carteiras = carteiras;

				if (setCarteiraInicial) {
                    let padrao = this.carteiras.find(f => f.padrao);
				    this.filtro.carteiraId = padrao.carteiraId;
                }

				this.obterListas();
			});
	}

	obterListas() {
		this.listaService.obterListaSemQuantidade(this.filtro).subscribe(listas => (this.listas = listas));
	}

	carteiraChange() {
		this.filtro.lista = null;
		this.obterListas();
	}


	filtrarEstrategia() {
		this.filtrar.emit(this.filtro);
	}

	limpar() {
		this.filtro = new FiltroLista();
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro.carteiraId = padrao.carteiraId;
		this.filtro.ignorarErroPesquisa = true;
		this.travarEscolhaLista = false;
	}

	keyDownFunction($event: any) {
		//if($event.keyCode == 13) 
		this.filtrarEstrategia();
	}
}
