import { QueryStringElasticsearchFilter } from "./elasticsearch-filter/query_string";
import { TermsElasticsearchFilter } from "./elasticsearch-filter/terms";
import { BaseElasticsearchFilter } from "./elasticsearch-filter/base";
import { NotTermsElasticsearchFilter } from "./elasticsearch-filter/not_terms";
import { TermElasticsearchFilter } from "./elasticsearch-filter/term";
import { FieldQuery } from "./elasticsearch-filter/field_query";
import { NotExistsQuery } from "./elasticsearch-filter/not";
import { EmptyQuery } from "./elasticsearch-filter/empty";
import { WildcardElasticsearchFilter } from "./elasticsearch-filter/wildcard";
import { NestedElasticsearchFilter } from "./elasticsearch-filter/nested-filter";

var filtrosTexto = [
	{
		name: "Igual",
		negation: false,
		keyword: true,
		content: "term",
		parameters: [{ name: "query", alias: "Igual" }],
		filter: TermElasticsearchFilter
	},
	{
		name: "Contém",
		negation: false,
		content: "query_string",
		parameters: [{ name: "query", alias: "Contém" }],
		filter: QueryStringElasticsearchFilter
	},
	{
		name: "Não contém",
		negation: true,
		content: "query_string",
		parameters: [{ name: "query", alias: "Não contém", negation: true }],
		filter: QueryStringElasticsearchFilter
	},
	{
		name: "Correspondente a",
		negation: false,
		content: "terms",
		parameters: [{ name: "query", alias: "Contém" }],
		filter: TermsElasticsearchFilter
	},
	{
		name: "Vazio",
		negation: true,
		content: "wildcard",
		parameters: [{ name: "query", alias: "Texto", negation: true }],
		filter: WildcardElasticsearchFilter
	},
	{
		name: "Não Vazio",
		negation: false,
		content: "wildcard",
		parameters: [{ name: "query", alias: "Texto" }],
		filter: WildcardElasticsearchFilter
	},
	{
		name: "Existe (retorno)",
		negation: false,
		content: "exists",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: FieldQuery
	},
	{
		name: "Não Existe (retorno)",
		negation: false,
		content: "script",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: EmptyQuery
	}
];

var filtrosNumerico = [
	{
		name: "Igual",
		negation: false,
		content: "match",
		parameters: [{ name: "query", alias: "Igual a" }],
		filter: BaseElasticsearchFilter
	},
	{
		name: "Diferente",
		negation: true,
		content: "match",
		parameters: [{ name: "query", alias: "Diferente de" }],
		filter: BaseElasticsearchFilter
	},
	{
		name: "Entre",
		negation: false,
		content: "range",
		parameters: [
			{ name: "gte", alias: "Maior ou igual" },
			{ name: "lte", alias: "Menor ou igual" }
		],
		filter: BaseElasticsearchFilter
	},
	{
		name: "Maior ou igual que",
		negation: false,
		content: "range",
		parameters: [{ name: "gte", alias: "Maior ou igual" }],
		filter: BaseElasticsearchFilter
	},
	{
		name: "Menor ou igual que",
		negation: false,
		content: "range",
		parameters: [{ name: "lte", alias: "Menor ou igual" }],
		filter: BaseElasticsearchFilter
	},
	{
		name: "Existe (retorno)",
		negation: false,
		content: "exists",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: FieldQuery
	},
	{
		name: "Não Existe (retorno)",
		negation: false,
		content: "script",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: EmptyQuery
	}
];

var filtrosData = [
	{
		name: "Entre",
		negation: false,
		content: "range",
		parameters: [
			{ name: "gte", alias: "Maior ou igual" },
			{ name: "lte", alias: "Menor ou igual" }
		],
		filter: BaseElasticsearchFilter
	},
	{
		name: "Menor que",
		negation: false,
		content: "range",
		parameters: [{ name: "lte", alias: "Menor ou igual" }],
		filter: BaseElasticsearchFilter
	},
	{
		name: "Maior que",
		negation: false,
		content: "range",
		parameters: [{ name: "gte", alias: "Maior ou igual" }],
		filter: BaseElasticsearchFilter
	},
	{
		name: "Vazio",
		negation: false,
		content: "script",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: EmptyQuery
	},
	{
		name: "Não Vazio",
		negation: false,
		content: "exists",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: FieldQuery
	},
	{
		name: "Existe (retorno)",
		negation: false,
		content: "exists",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: FieldQuery
	},
	{
		name: "Não Existe (retorno)",
		negation: false,
		content: "script",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: EmptyQuery
	}
];

var filtrosLista = [
	{
		name: "Contém",
		negation: false,
		content: "terms",
		parameters: [{ name: "query", alias: "Contém" }],
		filter: TermsElasticsearchFilter
	},
	{
		name: "Não contém",
		negation: false,
		content: "not_terms",
		parameters: [{ name: "query", alias: "Não contém" }],
		filter: NotTermsElasticsearchFilter
	},
	{
		name: "Vazio",
		negation: true,
		content: "wildcard",
		parameters: [{ name: "query", alias: "Texto", negation: true }],
		filter: WildcardElasticsearchFilter
	},
	{
		name: "Não Vazio",
		negation: false,
		content: "wildcard",
		parameters: [{ name: "query", alias: "Texto" }],
		filter: WildcardElasticsearchFilter
	},
	{
		name: "Existe (retorno)",
		negation: false,
		content: "exists",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: FieldQuery
	},
	{
		name: "Não Existe (retorno)",
		negation: false,
		content: "script",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: EmptyQuery
	}
];

var filtroBoolean = [
	// {
	// 	name: "Sim/Não",
	// 	negation: false,
	// 	content: "term",
	// 	parameters: [{ name: "query", alias: "Texto" }],
	// 	filter: TermElasticsearchFilter
	// },
	{
		name: "Sim",
		negation: false,
		content: "term",
		parameters: [{ name: "query", alias: "Texto" }],
		filter: TermElasticsearchFilter
	},
	{
		name: "Não",
		negation: false,
		content: "term",
		parameters: [{ name: "query", alias: "Texto" }],
		filter: TermElasticsearchFilter
	}/*,
	{
		name: "Não Vazio",
		negation: false,
		content: "exists",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: FieldQuery
	},
	{
		name: "Vazio",
		negation: false,
		content: "script",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: EmptyQuery
	}*/
];

var filtrosJson = [
	// Inteiro /////////////////////////////////////////////////////////////////////////////
	{
		name: "Contém",
		negation: false,
		content: "nested",
		parameters: [{ name: "query", alias: "Contém" }],
		filter: NestedElasticsearchFilter,
		nestedType: "Inteiro",
		nestedContent: "match",
		nestedList: true
	},
	{
		name: "Não contém",
		negation: true,
		content: "nested",
		parameters: [{ name: "query", alias: "Não contém", negation: true }],
		filter: NestedElasticsearchFilter,
		nestedType: "Inteiro",
		nestedContent: "match",
		nestedList: true
	},
	{
		name: "Existe",
		negation: false,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: NestedElasticsearchFilter,
		nestedType: "Inteiro",
		nestedContent: "exists",
		nestedList: false
	},
	{
		name: "Não Existe",
		negation: true,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: NestedElasticsearchFilter,
		nestedType: "Inteiro",
		nestedContent: "exists",
		nestedList: false
	},
	// Long ////////////////////////////////////////////////////////////////////////////////
	{
		name: "Igual",
		negation: false,
		content: "nested",
		parameters: [{ name: "query", alias: "Igual a" }],
		filter: NestedElasticsearchFilter,
		nestedType: "Long",
		nestedContent: "match",
		nestedList: false
	},
	{
		name: "Existe",
		negation: false,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: NestedElasticsearchFilter,
		nestedType: "Long",
		nestedContent: "exists",
		nestedList: false
	},
	{
		name: "Não Existe",
		negation: true,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: NestedElasticsearchFilter,
		nestedType: "Long",
		nestedContent: "exists",
		nestedList: false
	},
	// Data ////////////////////////////////////////////////////////////////////////////////
	{
		name: "Entre",
		negation: false,
		content: "nested",
		parameters: [
			{ name: "gte", alias: "Maior ou igual" },
			{ name: "lte", alias: "Menor ou igual" }
		],
		filter: NestedElasticsearchFilter,
		nestedType: "Data",
		nestedContent: "range",
		nestedList: false
	},
	{
		name: "Existe",
		negation: false,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: NestedElasticsearchFilter,
		nestedType: "Data",
		nestedContent: "exists",
		nestedList: false
	},
	{
		name: "Não Existe",
		negation: true,
		content: "nested",
		parameters: [{ name: "field", alias: "Campo" }],
		filter: NestedElasticsearchFilter,
		nestedType: "Data",
		nestedContent: "exists",
		nestedList: false
	}
]

export const FiltroPorTipo = {
	Texto: filtrosTexto,
	Inteiro: filtrosNumerico,
	Long: filtrosNumerico,
	Decimal: filtrosNumerico,
	Numerico: filtrosNumerico,
	Data: filtrosData,
	Lista: filtrosLista,
	Boolean: filtroBoolean,
	Nested: filtrosJson
};
