import { Login } from "../model/login";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { AutenticacaoService } from "../../common/service/autenticacao.service";
import { UsuarioService } from "../../common/service/usuario.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { PermissoesUsuario } from "../../common/model/permissoes-usuario";
import { PermissaoDefault } from "../../common/model/permissao-default";
import { MatDialog } from "@angular/material";
import { EsqueciSenhaComponent } from "../esqueci-senha/esqueci-senha.component";
import { AlterarSenhaComponent } from "../alterar-senha/alterar-senha.component";
import { environment } from "../../../../environments/environment";
import { ConfiguracaoService } from "../../common/service/configuracao.service";
import { WINDOW } from "../../../common/providers/window.provider";
import { Injectable, Inject } from '@angular/core';
@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
	providers: [
		{ useClass: CustomAlertService, provide: CustomAlertService },
		{ useClass: UsuarioService, provide: UsuarioService },
		{ useClass: ConfiguracaoService, provide: ConfiguracaoService }
	]
})
export class LoginComponent implements OnInit {
	public login: Login;
	public AppConfig: any;
	public mostrarForm: boolean = false;
	public loginOktaAtivo: boolean = false;

	logo: string = 'talkmaestro';

	constructor(
		private autenticacaoService: AutenticacaoService,
		private customAlertService: CustomAlertService,
		private usuarioService: UsuarioService,
		private configService: ConfiguracaoService,
		private router: Router,
		public dialog: MatDialog,
		private route: ActivatedRoute,
		@Inject(WINDOW) private window: Window
	) {
		this.login = new Login();

		if (this.window.location.hostname.includes('.teajudei'))
			this.logo = 'teajudei';

		if (this.window.location.hostname.includes('.epbx'))
			this.logo = 'talkmaestro';

		if (this.window.location.hostname.includes('.brbots'))
			this.logo = 'brbots';


	}

	ngOnInit() {
		this.AppConfig = environment;

		this.configService.loginOktaAtivo().subscribe(response => {
			this.loginOktaAtivo = response.loginOktaAtivo;
		})

		this.route.queryParams.subscribe(params => {
			if (params.logadoOkta) {

				let loginOkta = new Login();
				loginOkta.login = params.hash;
				loginOkta.senha = 'b3f7725bd1f13d03901aa21a821283de';

				this.autenticacaoService.realizarLogin(loginOkta).subscribe(
					credential => this.obterPermissoes(),
					error => {
						this.customAlertService.show(
							"Login",
							"login.usuarioOuSenhaInvalido",
							"error"
						);
					}
				);

				return;
			}

			if (!params.logado) {
				this.checarAlteracaoSenha(params)
				this.mostrarForm = true;
				return;
			}

			this.autenticacaoService.obterCredenciaisPorCookie().subscribe(
				res => this.obterPermissoes(),
				error => {
					this.customAlertService.show(
						"Login",
						"login.autenticacaoFalhou",
						"error"
					);
				}
			);
		});
	}

	checarAlteracaoSenha(params) {
		if (params.key) {
			this.usuarioService.verificarChaveAlteracao(params.key).subscribe(s => {
				if (s.isValid) {
					this.login.login = s.data.login;
					let alterarSenhaRef = this.dialog.open(AlterarSenhaComponent, {
						disableClose: true,
						height: "380px",
						width: "500px",
						data: {
							chave: params.key,
							data: s.data
						}
					});
				}
				else {
					this.customAlertService.show(
						"Alteração de senha",
						"login.chaveAlteracaoInvalida",
						"error"
					);
				}
			}
			);
		}
	}

	recuperarSenha() {
		this.dialog.open(EsqueciSenhaComponent, {
			height: "300px",
			width: "500px",
			data: {
			}
		});
	}

	realizarLogin() {
		this.autenticacaoService.realizarLogin(this.login).subscribe(
			credential => this.obterPermissoes(),
			error => {
				this.customAlertService.show(
					"Login",
					"login.usuarioOuSenhaInvalido",
					"error"
				);
			}
		);
	}

	obterPermissoes() {
		this.autenticacaoService.obterPermissoes().subscribe(
			permissoes => {
				PermissoesUsuario.recursos = permissoes.permissaoRecurso;
				PermissoesUsuario.carteiras = permissoes.permissaoCarteira;
				PermissoesUsuario.listas = permissoes.permissaoLista;
				return this.router.navigate([
					PermissaoDefault.obterRotaPadraoRedirecionamento()
				]);
			},
			error => {
				this.customAlertService.show(
					"Login",
					"login.autenticacaoFalhou",
					"error"
				);
			}
		);
	}

	loginOkta() {
		window.location.href = `${this.AppConfig.serverUrl}/Autenticacao/Okta/Authorize`
	}
}
