
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../../environments/environment";
import { ElasticsearchResultado } from "../model/elasticsearch-resultado";

@Injectable()
export class ElasticsearchRepositoryService {
	constructor(private _http: HttpClient) { }

	public getFromIndex(
		path: String,
		index: String,
		query?
	): Observable<ElasticsearchResultado> {
		return this._http.post<ElasticsearchResultado>(
			`${path}/api/Search/${index}`,
			query
		);
	}

	public getOptionForField(
		index: string,
		listaId: any,
		templateId: any,
		field: string,
		query: any,
		negation: boolean = false
	) {
		
		field = field.replace('.keyword', '');
		return this._http.post<Array<string>>(
			`${environment.serverUrl}/api/Search/${index}/${listaId}/${templateId}/${field}/${negation}`,
			query
		);
	}

	public preview(index: string, listaId: string, templateId: string, query?): Observable<any> {
		return this._http.post<Array<any>>(
			`${environment.serverUrl}/api/Search/${index}/${listaId}/${templateId}`,
			query
		);
	}

	public downloadPreview(index: string, listaId: string, templateId: string, query?): Observable<any> {
		return this._http.post<Array<any>>(
			`${environment.serverUrl}/api/Search/download/${index}/${listaId}/${templateId}`,
			query
		);
	}

	public listaItem(index: string, listaId: number, templateId: string, query?): Observable<any> {
		return this._http.post<Array<any>>(
			`${environment.serverUrl}/api/Search/item/${index}/${listaId}/${templateId}`,
			query
		);
	}

	public getQuantidadeFiltros(index: String, query?) {
		return this._http.post<Array<number>>(
			`${environment.serverUrl}/api/Search/quantidadeFiltros/${index}`,
			query
		);
	}

	public getQuantidadeFiltrosOrdem(index: String, query, ordem = null) {
		return this._http.post<Array<number>>(
			`${environment.serverUrl}/api/Search/quantidadeFiltrosOrdem/${index}`,
			{ "query": query, "ordem": ordem }
		);
	}

	public getQuantidadeLista(idLista, idEstrategia, query, campo, operacao, campoAgrupadorSomaUnicos, formatacao, somaUnicosCardFormatacao) {
		let parametros = campo ? `?campoExtra=${campo}&operacao=${operacao}` : '';

		if (campo && campoAgrupadorSomaUnicos)
			parametros = parametros + `&campoAgrupadorSomaUnicosExtra=${campoAgrupadorSomaUnicos}`;

		if (campo && formatacao)
			parametros = parametros + `&formatacao=${formatacao}`;

		if (somaUnicosCardFormatacao)
			parametros = parametros + `&somaUnicosCardFormatacao=${somaUnicosCardFormatacao}`;


		return this._http.post<Array<number>>(
			`${environment.serverUrl
			}/api/Search/quantidades-lista/${idLista}/${idEstrategia}` + parametros,
			query
		);
	}

	public getFields(path: string, index: string) {
		return this._http.get(`${path}/api/Search/${index}`).pipe(map(result => {
			if (result["error"]) throw "Index not found";

			var properties = result[index].mappings[index].properties;
			return Object.keys(properties).map(property => ({
				property,
				...properties[property]
			}));
		}));
	}
}
