import * as validate from "validate.js";
import { CarteiraUsuario } from "./carteira-usuario";
import { UsuarioPermissao } from "./usuario-permissao";
import { UsuarioPermissaoLista } from "./usuario-permissao-lista";

export enum TipoUsuario {
	Administrador = 1,
	ApiKey = 2,
	Manager = 3,
	Operador = 4
}

export class Usuario {
	usuarioId: number;
	usuarioTipoId: TipoUsuario;
	usuarioTipo: string;
	nome: string;
	email: string;
	login: string;
	senha: string;
	repetirSenha: string;
	validarSenha: boolean;
	dataCadastro: Date;
	permissoes: Array<UsuarioPermissao>;
	carteiraUsuario: Array<CarteiraUsuario>;
	usuarioPermissaoLista: Array<UsuarioPermissaoLista>;
	loginUnico = true;

	constructor(nome?: string, login?: string, usuarioTipoId?: TipoUsuario, id?) {
		this.usuarioId = id;
		this.login = login;
		this.nome = nome;
		this.usuarioTipoId = usuarioTipoId;
		this.permissoes = [];
		this.usuarioPermissaoLista = [];
	}

	static fromRaw(rawUsuario): Usuario {
		return Object.assign(new Usuario(), rawUsuario);
	}

	validate() {
		validate.validators.semEspacos = function (value) {
			if (!value || value.indexOf(" ") == -1) return null;
			return "espaco";
		};
		validate.validators.senhaIgual = function (value, options, key, usuario) {
			if (usuario.senha != value) return "igual";
			return null;
		};

		let validationResult = validate.validate(this, {
			nome: {
				presence: { allowEmpty: false }
			},
			login: {
				presence: { allowEmpty: false, message: "obrigatorio", tab: "AAA" },
				length: { minimum: 3, message: "minimo" },
				semEspacos: true
			},
			usuarioTipoId: {
				presence: { allowEmpty: false }
			},
			permissoes: {
				presence: true
			}
		});

		if (this.usuarioTipoId != TipoUsuario.ApiKey && this.usuarioTipoId != TipoUsuario.Manager && this.validarSenha) {
			var validationOptions = {
				senha: { presence: true },
				repetirSenha: { senhaIgual: true }
			};
			var senhaValidation = validate.validate(this, validationOptions);

			if (senhaValidation != null) {
				if (validationResult == null) validationResult = {};
				Object.assign(validationResult, senhaValidation);
			}
		}

		if (this.email) {
			var emailValidation = validate.validate(this, { email: { email: true } });
			if (emailValidation != null) {
				if (validationResult == null) validationResult = {};
				Object.assign(validationResult, emailValidation);
			}
		}
		if (!this.loginUnico) {
			if (validationResult == null) validationResult = {};
			validationResult["loginUnico"] = ["Login já existe"];
		}

		return validationResult;
	}

	campoTab: any = {
		'nome': 0,
		'login': 2,
		'usuarioTipoId': 0,
		'permissoes': 0,
		'email': 0,
		'senha': 0,
		'repetirSenha': 0,
		'loginUnico': 0
	}
}
