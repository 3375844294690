import { Component, OnInit, ViewChild } from "@angular/core";
import { FiltroLista } from "../../common/model/filtro-lista";
import { MatTabGroup, MatDialog } from "@angular/material";
import { Subject, Operator, Subscription, forkJoin, Observable } from "rxjs";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { TemplateService } from "../../common/service/template.service";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { ListaService } from "../../common/service/lista.service";
import { ElasticsearchRepositoryService } from "../../elasticsearch/repository/elasticsearch-repository.service";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import * as _ from 'lodash';
import { montarQueryPesquisaCampo } from "../utils/montarQueryPesquisaCampo";

import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";
import { Template } from "../../common/model/template";
import { isNull, isObjectEmpty } from "../../common/utils";

@Component({
    selector: "app-pesquisa-estrategia",
    templateUrl: "./pesquisa-estrategia.component.html",
    styleUrls: ["./pesquisa-estrategia.component.css"],
    providers: [
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: ListaService, provide: ListaService },
        { useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})

export class PesquisaEstrategiaComponent implements OnInit {
    public filtro: any = { 'carteiraId': null, 'template': null, 'tipo': null, 'valor': null };
    public listaTemplateItensCollectionOriginal = [];
    public listaTemplateItensCollection = [];
    public estrategiaCollection = [];
    public listaCollection = [];
    public abaVisivel: any;

    carteiras: Array<Carteira> = [];
    templates: Array<Template> = [];

    @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
    constructor(private carteiraService: CarteiraService,
        private templateService: TemplateService,
        private estrategiaService: EstrategiaService,
        private listaService: ListaService,
        private searchService: ElasticsearchRepositoryService,
        public dialog: MatDialog) {

        this.obterCarteiras();
    }

    ngOnInit() { }

    //#region [ Eventos ]

    carteiraChange() {
        this.filtro.template = null;
        this.filtro.tipo = null;
        this.obterTemplates();
    }

    templateChange() {
        this.filtro.tipo = null;
        this.obterCamposMapeados();
    }

    //#endregion

    //#region [ Consultar dados basicos ]

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
                this.carteiras = carteiras;

                let padrao = this.carteiras.find(f => f.padrao);
                this.filtro.carteiraId = padrao.carteiraId;

                this.obterTemplates();
                this.obterCamposMapeados();
            });
    }

    obterTemplates() {
        this.templateService
            .obterTemplates({ 'carteiraId': this.filtro.carteiraId, 'categoria': 1 })
            .subscribe(templates => (this.templates = templates));
    }

    obterCamposMapeados() {
        this.templateService
            .obterTodosCamposMapeados({ 'carteiraId': this.filtro.carteiraId, 'templateId': this.filtro.template })
            .subscribe(result => {
                this.listaTemplateItensCollectionOriginal = result;
                this.listaTemplateItensCollection = result.map(m => ({ 'value': m.listaTemplateItemId, 'label': m.nome }));
            });
    }

    // obterEstrategias() {
    //     this.estrategiaService.obterEstrategias({ 'ativo': 1, 'carteiraId': this.filtro.carteiraId, 'templateId': this.filtro.template }).subscribe(result => { this.estrategiaCollection = result; })
    // }

    // obterListas() {
    //     let filtro = new FiltroLista();
    //     filtro.status = '3';
    //     filtro.template = this.filtro.template;
    //     filtro.carteiraId = this.filtro.carteiraId;
    //     this.listaService.obterListaSemQuantidade(filtro).subscribe(result => { this.listaCollection = result; });
    // }

    //#endregion

    pesquisar() {

        let filtroLista = new FiltroLista();
        filtroLista.status = '3';
        filtroLista.template = this.filtro.template;
        filtroLista.carteiraId = this.filtro.carteiraId;

        let p1 = this.estrategiaService.obterEstrategias({ 'ativo': 1, 'carteiraId': this.filtro.carteiraId, 'templateId': this.filtro.template });
        let p2 = this.listaService.obterListaSemQuantidade(filtroLista);

        forkJoin([p1, p2]).subscribe((results: any) => {
            this.estrategiaCollection = results[0];
            this.listaCollection = results[1];

            this.verificarEstrategias();
            this.verificarListas();
            this.tabGroup.selectedIndex = 0;
        });

    }

    verificarEstrategias() {
        this.limpaTotalEstrategias();

        let templateItem = this.obterItem(this.filtro.tipo);

        if (templateItem != null) {

            this.estrategiaCollection.forEach(estrat => {

                if (estrat.lista.templateId != templateItem.templateId) {
                    estrat.total = 0;
                } else {

                    let _json = (typeof estrat.filtro === 'string') ? JSON.parse(estrat.filtro) : estrat.filtro;
                    let campo = templateItem.nome[0].toLowerCase() + templateItem.nome.substring(1);
                    let condicao = { "term": {} };
                    condicao.term[campo] = this.filtro.valor;
                    _json.query.bool.must.push(condicao);
                    estrat.filtro = _json;

                    this.searchService.preview(estrat.lista.alias, estrat.lista.listaId, estrat.lista.templateId, _json).subscribe(result => {
                        estrat.total = result.total;
                    });
                }
            });
        }
    }

    verificarListas() {
        this.limpaTotalListas();

        let templateItem = this.obterItem(this.filtro.tipo);

        if (templateItem != null) {

            this.listaCollection.forEach(list => {


                if (list.templateId != templateItem.templateId) {
                    list.total = 0;
                } else {
                    list.filtro = montarQueryPesquisaCampo(templateItem.nome, this.filtro.valor, templateItem.listaCampoTipoId);

                    this.searchService.preview(list.alias, list.listaId, list.templateId, list.filtro).subscribe(result => {
                        list.total = result.total;
                    });
                }
            });
        }
    }

    obterItem(listaTemplateItemId) {
        return this.listaTemplateItensCollectionOriginal.find(f => f.listaTemplateItemId == listaTemplateItemId);
    }

    obterTemplateItem(listaTemplateItemId) {
        let item = this.obterItem(listaTemplateItemId);
        return (item == null) ? '' : (item).templateNome;
    }

    obterTipoItem(listaTemplateItemId) {
        let item = this.obterItem(listaTemplateItemId);
        return (item == null) ? '' : (item).tipoDado;
    }

    mostrarPreview(lista: any) {
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: lista.templateId,
                lista: lista.nome,
                listaId: lista.listaId,
                query: lista.filtro
            }
        });
    }

    limpar() {
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro = { 'carteiraId': padrao.carteiraId, 'template': null, 'tipo': null, 'valor': null };

        this.estrategiaCollection = [];
        this.listaCollection = [];

        this.limpaTotalEstrategias();
        this.limpaTotalListas();
    }

    isNumber(value: string | number): boolean {
        return ((value != null) && !isNaN(Number(value.toString())));
    }

    getEstrategiasOrdernado() {
        return _.sortBy(this.estrategiaCollection, "total").reverse();
    }

    getListasOrdernado() {
        return _.sortBy(this.listaCollection, "total").reverse();
    }

    limpaTotalEstrategias() {
        this.estrategiaCollection.forEach(item => { item.total = undefined; });
    }

    limpaTotalListas() {
        this.listaCollection.forEach(item => { item.total = undefined; });
    }

    customSearchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.value.toString().toLocaleLowerCase().indexOf(term) > -1 || item.label.toString().toLocaleLowerCase().indexOf(term) > -1;
    }
}
