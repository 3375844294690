import { validate } from "validate.js";
import { DownloadRegistroStatus } from "./download-registro";

export class FiltroDownloadRegistro {
    public listaId: number;
    public dataInicio: Date;
    public dataFim: Date;
    public downloadRegistroStatusId: DownloadRegistroStatus;

    validate() {
        let validacao: any = {

            dataInicio: {
                presence: { allowEmpty: false, message: "campoObrigatorio" },
                dataMenorQue: { campo: "dataFim" }
            },
            dataFim: {
                presence: { allowEmpty: false, message: "campoObrigatorio" }
            }
        };

        return validate(this, validacao);
    }
}