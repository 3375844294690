import { APP_INITIALIZER, NgModule } from "@angular/core";

import { LayoutRoutingModule } from "./layout-routing.module";
import { SharedModule } from "../shared/shared.module";
import { AuthGuard } from "../modules/common/auth.guard";
import { FabMenuComponent } from "./fab-menu/fab-menu.component";
import { SimpleNotificationsModule } from "angular2-notifications";
import { APPCONFIG, ConfigProvider, ConfigProviderFactory } from "../common/providers/config.provider";

@NgModule({
  imports: [LayoutRoutingModule, SharedModule, SimpleNotificationsModule],
  entryComponents: [],
  declarations: [],
  providers: [
    { useClass: AuthGuard, provide: AuthGuard },
    { provide: APP_INITIALIZER, useFactory: ConfigProviderFactory, deps: [ConfigProvider], multi: true },
    { provide: APPCONFIG, useFactory: (provider: ConfigProvider) => provider.getConfig(), deps: [ConfigProvider] }
  ]
})
export class LayoutModule { }
