import { MatDialog, MatSliderChange } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Estrategia, TipoEstrategia, EstrategiaAcaoCampanhaTipo, EstrategiasFilhas, EstrategiaTotal } from "../../common/model/estrategia";
import { Location } from '@angular/common';
import { Subject, Operator, Subscription, forkJoin, Observable } from "rxjs";

import { AgendamentoEstrategiaComponent } from "./agendamento-estrategia/agendamento-estrategia.component";
import { PreviewDadosEstrategiaComponent } from "./preview-dados-estrategia/preview-dados-estrategia.component";
import { ElasticsearchFilterComponent } from "../../elasticsearch/filter/elasticsearch-filter/elasticsearch-filter.component";
import { ElasticsearchAddOrderComponent } from "../../elasticsearch/elasticsearch-add-order/elasticsearch-add-order.component";
import { FiltroQueryPadraoModalComponent } from "../../filtro-query-padrao/filtro-query-padrao-modal/filtro-query-padrao-modal.component";

import { EstrategiaService } from "../../common/service/estrategia.service";
import { TemplateService } from "../../common/service/template.service";
import { ListaService } from "../../common/service/lista.service";
import { CampanhaService } from "../../common/service/campanha.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { Template, TipoCategoria } from "../../common/model/template";
import { EstrategiaTipo } from "../../common/model/estrategia-tipo";
import { ProviderService } from "../../common/service/provider.service";
import { Provider } from "../../common/model/provider";
import { TranslateService } from "@ngx-translate/core";
import 'hammerjs';
import { isNullOrZero, isNull, isNullOrEmpty } from "../../common/utils";
import { IntegracaoTipo } from "../../common/model/provedor-configuracao";
import { environment } from "../../../../environments/environment";
import { FiltroStatusRegistroLista } from "../../common/model/lista";
import { FiltroQuery } from "../../common/model/filtro-query";
import { flatMap } from "rxjs/operators";
import swal from "sweetalert2";

@Component({
    selector: "app-form-estrategia",
    templateUrl: "./form-estrategia.component.html",
    styleUrls: ["./form-estrategia.component.scss"],
    providers: [
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: ListaService, provide: ListaService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: CampanhaService, provide: CampanhaService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: ProviderService, provide: ProviderService }
    ]
})

export class FormEstrategiaComponent implements OnInit {
    estrategia: Estrategia;
    lista: Array<{
        listaId: number;
        providerId: number;
        templateId: number;
        nome: string;
        alias: string;
    }>;
    listaTemplateExportacao: Array<Template>;
    listaTemplateExportacaoSelect: Array<Template>;
    listaEstrategiaTipo: Array<EstrategiaTipo>;
    bloqueiaEscolhaLista: boolean;
    listaEscolhida: any;
    quantidadeEstrategia: number;
    isEdit: boolean = false;
    campanhas: Array<any>;
    dirty: boolean;
    finishedLoading: boolean;
    existeTemplateExportacao: boolean = false;

    _envioPorLote;

    dadosQuantidadeAlterada: EstrategiaTotal;

    AppConfig: any;


    public get envioPorLote(): boolean {
        return this._envioPorLote;
    }

    public set envioPorLote(valor: boolean) {
        if (!valor)
            this.estrategia.quantidadeMaximaExportacao = 0;
        if (valor && this.estrategia.quantidadeMaximaExportacao == 0)
            this.estrategia.quantidadeMaximaExportacao = this.estrategia.quantidadeLote;

        this._envioPorLote = valor;
    }

    public get estrategiasFilhas(): Array<EstrategiasFilhas> {
        return this.estrategia.estrategiasFilhas.filter(f => f.ativo);
    }

    @ViewChild("elasticsearchFilter", { static: false })
    elasticsearchFilter: ElasticsearchFilterComponent;
    providerCollection: Provider[];

    constructor(
        private estrategiaService: EstrategiaService,
        private templateService: TemplateService,
        private route: ActivatedRoute,
        private listaService: ListaService,
        private router: Router,
        public dialog: MatDialog,
        private customAlertService: CustomAlertService,
        private providerService: ProviderService,
        private _location: Location,
        private translate: TranslateService
    ) {

        this.AppConfig = environment;
        this.lista = [];
        this.estrategia = new Estrategia();

        // Setando valores padrão para a criação de uma nova estratégia
        this.estrategia.reenviarDistribuido = FiltroStatusRegistroLista.naoUtilizado;
        this.estrategia.enviarEstrategiaIdExportacao = false;
        this.estrategia.marcarDistribuido = true;
        this.estrategia.envioImediato = false;
        this.estrategia.notificarZerada = false;

        this.dadosQuantidadeAlterada = new EstrategiaTotal();

        this.estrategia.listaEstrategiaId = 0;
        this.estrategia.listaEstrategiaTipoId = TipoEstrategia.provedor;
        this.popularTemplateCategoria();
        this.listaService
            .obterListaComTemplate({})
            .subscribe(lista => (this.lista = lista));

        this.route.params.subscribe(params => {

            if (params.id) {
                this.isEdit = true;
                this.estrategiaService
                    .obterEstrategiaPorId(params.id)
                    .subscribe(estrategia => {
                        this.estrategia = estrategia;

                        if (!isNullOrZero(this.AppConfig.estrategiaAgendamentoMinimoMinutos))
                            this.estrategia.estrategiaAgendamentoMinimoMinutos = this.AppConfig.estrategiaAgendamentoMinimoMinutos;

                        this.envioPorLote = estrategia.quantidadeMaximaExportacao > 0;
                        this.updateQuery(JSON.parse(this.estrategia.filtro));
                        this.finishedLoading = true;

                        let p1 = this.providerService.obterProvedorDisponivelPorLista(this.estrategia.listaId);
                        let p2 = this.templateService.obterTemplates({ listaId: this.estrategia.listaId, filtroEstrategia: true, categoria: 2 });

                        forkJoin([p1, p2]).subscribe((results: any) => {
                            this.providerCollection = results[0].map(p => this.providerService.convertObject(p))
                            this.listaTemplateExportacao = results[1].filter((f: any) => (f.listaTemplateCategoriaId == TipoCategoria.exportacao));
                            this.getCampanha(this.estrategia.providerId);
                        });
                    });
            } else {
                this.finishedLoading = true;
                this.envioPorLote = false;
                this.estrategia.recorrente = false;
                this.estrategia.estrategiasFilhas[0].quantidadeMaximaExportacaoPct = 100;

                if (!isNullOrZero(this.AppConfig.estrategiaAgendamentoMinimoMinutos))
                    this.estrategia.estrategiaAgendamentoMinimoMinutos = this.AppConfig.estrategiaAgendamentoMinimoMinutos;
            }

            if (params.listaId) {
                this.estrategia.listaId = parseInt(params.listaId);
                this.bloqueiaEscolhaLista = true;
                this.obterProvedores(params.listaId);
                this.popularListaTemplateImportacao();
            }

            if (params.reguaId) {
                this.estrategia.reguaId = params.reguaId;

                if (params.reguaEstrategiaId)
                    this.estrategia.reguaEstrategiaId = params.reguaEstrategiaId;
            }

            if (params.fluxoId) {
                this.estrategia.fluxoId = params.fluxoId;

                if (params.fluxoProcessoId)
                    this.estrategia.fluxoProcessoId = params.fluxoProcessoId;
            }
        });
    }

    public get enviarReutilizar() {
        return !this.estrategia.marcarDistribuido;
    }

    public set enviarReutilizar(reutilizar) {
        this.estrategia.marcarDistribuido = !reutilizar;
    }

    obterProvedores(listaId) {
        this.providerService
            .obterProvedorDisponivelPorLista(listaId)
            .subscribe(providerCollection => this.providerCollection = providerCollection.map(p => this.providerService.convertObject(p)));
    }

    popularListaTemplateImportacao() {
        this.estrategia.estrategiasFilhas = [new EstrategiasFilhas(EstrategiaAcaoCampanhaTipo.ImportarCampanha, true, this.estrategia.medidaMaximaExportacaoPct)];
        this.estrategia.estrategiasFilhas[0].quantidadeMaximaExportacaoPct = 100;

        if (this.estrategia.listaId) {

            this.templateService
                .obterTemplates({ listaId: this.estrategia.listaId, filtroEstrategia: true, categoria: 2 })
                .subscribe((listaTemplate: any) => {
                    this.listaTemplateExportacao = listaTemplate.filter(function (f: any) {
                        return f.listaTemplateCategoriaId == TipoCategoria.exportacao;
                    });
                });
        }
    }

    provedorConfiguracaoPorCampanha(providerId: number) {
        if (!providerId || isNull(this.providerCollection)) return false;
        let provedor = this.providerCollection.find((f: Provider) => f.providerId == providerId);
        return provedor.configuracaoPorCampanha;
    }


    providerDeSelect(providerId: any, estrategiaFilha: EstrategiasFilhas) {
        estrategiaFilha.providerId = null;
        estrategiaFilha.listaEstrategiaTipoId = null;
        estrategiaFilha.existeTemplateExportacao = null;
        estrategiaFilha.listaTemplateExportacaoId = null;
        estrategiaFilha.habilitaCampoCampanha = false;
        estrategiaFilha.campanhaId = null;
    }

    providerChange(providerId: any, estrategiaFilha: EstrategiasFilhas) {
        if (!providerId) return;

        let provider = this.providerCollection.find((f: Provider) => f.providerId == providerId);
        estrategiaFilha.listaTemplateExportacaoId = null;
        estrategiaFilha.campanhaId = null;

        // Função auxiliar
        let obterCampanhas: Function = () => {
            this.providerService
                .obterCampanhasConfiguradas(this.estrategia.listaId, providerId)
                .subscribe(campanhas => {
                    estrategiaFilha.campanhaSelect = campanhas;
                    estrategiaFilha.habilitaCampoCampanha = provider.campanha.layoutPorCampanha;

                    if (!isNull(provider.campanha) && provider.campanha.layoutPorCampanha) {
                        let templateSelecionado = this.listaTemplateExportacao.find(t => t.listaTemplateId == estrategiaFilha.listaTemplateExportacaoId.toString());

                        if (!isNullOrZero(templateSelecionado.campanhaId)) {
                            estrategiaFilha.campanhaId = templateSelecionado.campanhaId;
                        }
                    }
                });
        };

        if (this.listaTemplateExportacao) {
            estrategiaFilha.existeTemplateExportacao = this.listaTemplateExportacao.some((i: any) => (i.providerId == providerId));
            estrategiaFilha.listaTemplateExportacaoSelect = this.listaTemplateExportacao.filter((f: any) => (f.providerId == providerId));

            if (estrategiaFilha.listaTemplateExportacaoSelect.length === 1)
                estrategiaFilha.listaTemplateExportacaoId = estrategiaFilha.listaTemplateExportacaoSelect[0].listaTemplateId;

            estrategiaFilha.listaEstrategiaTipoId = (!isNullOrEmpty(provider.envio.integracaoTipoId == IntegracaoTipo.Arquivo)) ? 2 : 1;
            if (provider.configuracaoPorCampanha == false) return;

            obterCampanhas();

        } else {
            this.templateService
                .obterTemplates({ listaId: this.estrategia.listaId, filtroEstrategia: true, categoria: 2 })
                .subscribe((listaTemplate: any) => {
                    this.listaTemplateExportacao = listaTemplate.filter(function (f: any) {
                        return f.listaTemplateCategoriaId == TipoCategoria.exportacao;
                    });
                    estrategiaFilha.existeTemplateExportacao = this.listaTemplateExportacao.some((i: any) => (i.providerId == providerId));
                    estrategiaFilha.listaTemplateExportacaoSelect = this.listaTemplateExportacao.filter((f: any) => (f.providerId == providerId));

                    if (estrategiaFilha.listaTemplateExportacaoSelect.length === 1)
                        estrategiaFilha.listaTemplateExportacaoId = estrategiaFilha.listaTemplateExportacaoSelect[0].listaTemplateId;

                    estrategiaFilha.listaEstrategiaTipoId = (!isNullOrEmpty(provider.envio.integracaoTipoId == IntegracaoTipo.Arquivo)) ? 2 : 1;
                    if (provider.configuracaoPorCampanha == false) return;

                    obterCampanhas();
                });
        }
    }

    listaTemplateExportacaoChange(templateExportacaoId: any, estrategiaFilha: EstrategiasFilhas) {

        if (!estrategiaFilha.providerId) return;

        let provider = this.providerCollection.find((f: Provider) => f.providerId == estrategiaFilha.providerId);
        estrategiaFilha.listaEstrategiaTipoId = (!isNullOrEmpty(provider.envio.integracaoTipoId == IntegracaoTipo.Arquivo)) ? 2 : 1;
        estrategiaFilha.campanhaId = null;

        //if (templateExportacaoId && estrategiaFilha.listaEstrategiaTipoId == 1) 
        if (templateExportacaoId && provider.configuracaoPorCampanha)
            this.getCampanha(estrategiaFilha.providerId, estrategiaFilha);

    }

    listaTemplateExportacaoDeSelect(templateExportacaoId: any, estrategiaFilha: any) {
        estrategiaFilha.listaEstrategiaTipoId = null;
        estrategiaFilha.campanhaSelect = [];
    }

    getCampanha(providerId: number, filha: EstrategiasFilhas = null) {
        if (filha == null)
            this.estrategiasFilhas.forEach((f: EstrategiasFilhas) => (this.obterCampanhas(f)));
        else
            this.obterCampanhas(filha);
    }

    obterCampanhas(estrategiaFilha: EstrategiasFilhas) {
        this.verificaExisteTemplateExportacao(estrategiaFilha);

        let provider = this.providerCollection.find((f: Provider) => f.providerId == estrategiaFilha.providerId);
        estrategiaFilha.listaEstrategiaTipoId = (provider.envio.integracaoTipoId == IntegracaoTipo.Arquivo) ? 2 : 1;

        //if (estrategiaFilha.listaEstrategiaTipoId == 2) return;
        if (provider.configuracaoPorCampanha == false) return;

        this.providerService
            .obterCampanhasConfiguradas(this.estrategia.listaId, estrategiaFilha.providerId)
            //.subscribe(campanhas => (estrategiaFilha.campanhaSelect = campanhas));
            .subscribe(campanhas => {
                estrategiaFilha.campanhaSelect = campanhas;
                estrategiaFilha.habilitaCampoCampanha = provider.campanha.layoutPorCampanha;

                if (!isNull(provider.campanha) && provider.campanha.layoutPorCampanha) {
                    let templateSelecionado = this.listaTemplateExportacao.find(t => t.listaTemplateId == estrategiaFilha.listaTemplateExportacaoId.toString());

                    if (!isNullOrZero(templateSelecionado.campanhaId)) {
                        estrategiaFilha.campanhaId = templateSelecionado.campanhaId;
                    }
                }
            });

    }

    verificaExisteTemplateExportacao(filha: EstrategiasFilhas) {

        if (this.listaTemplateExportacao) {
            filha.existeTemplateExportacao = this.listaTemplateExportacao.some((i: any) => (i.providerId == filha.providerId));
            filha.listaTemplateExportacaoSelect = this.listaTemplateExportacao.filter((f: any) => (f.providerId == filha.providerId));
        } else {
            if (this.estrategia.listaId) {
                this.templateService
                    .obterTemplates({ listaId: this.estrategia.listaId, filtroEstrategia: true, categoria: 2 })
                    .subscribe((listaTemplate: any) => {
                        this.listaTemplateExportacao = listaTemplate.filter(function (f: any) {
                            return f.listaTemplateCategoriaId == TipoCategoria.exportacao;
                        });
                        filha.existeTemplateExportacao = this.listaTemplateExportacao.some((i: any) => (i.providerId == filha.providerId));
                        filha.listaTemplateExportacaoSelect = this.listaTemplateExportacao.filter((f: any) => (f.providerId == filha.providerId));
                    });
            }
        }
    }

    popularTemplateCategoria() {
        this.listaEstrategiaTipo = new Array<EstrategiaTipo>();
        let itemProvedor = new EstrategiaTipo();
        itemProvedor.descricao = "Provedor";
        itemProvedor.estrategiaTipoId = TipoEstrategia.provedor;

        let itemApi = new EstrategiaTipo();
        itemApi.descricao = "Arquivo";
        itemApi.estrategiaTipoId = TipoEstrategia.arquivo;

        this.listaEstrategiaTipo.push(itemProvedor);
        this.listaEstrategiaTipo.push(itemApi);
    }

    public get ehListaEpbx(): boolean {
        if (!this.estrategia.listaId) return false;
        var lista = this.lista.find(l => l.listaId == this.estrategia.listaId);
        if (!lista) return false;
        return lista.providerId == 1;
    }

    public get nomeLista(): string {
        if (!this.estrategia.listaId || !this.lista) return "";
        let lista = this.lista.find(l => l.listaId == this.estrategia.listaId);
        return lista ? lista.alias : "";
    }

    public get templateId(): number {
        if (!this.estrategia.listaId || !this.lista) return null;
        let lista = this.lista.find(l => l.listaId == this.estrategia.listaId);
        return lista ? lista.templateId : null;
    }

    public get erros() {
        if (!this.dirty) return;
        return this.estrategia.validar();
    }



    query: any;

    ngOnInit() { }

    updateQuery(query) {

        this.query = query;
    }

    isListaSelecionada() {
        return (this.estrategia.listaId == undefined || this.estrategia.listaId <= 0);
    }

    mostrarPreview(query: any = null) {

        if (isNullOrEmpty(this.templateId) || isNullOrEmpty(this.nomeLista))
            return;


        if (query == undefined) {
            if (this.query == undefined) {
                query = {
                    size: this.elasticsearchFilter.pageSize,
                    _source: { includes: [] },
                    query: { bool: { must_not: this.elasticsearchFilter.mustNot } }
                }
            } else {
                query = this.query;
            }
        }

        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: this.templateId,
                lista: this.nomeLista,
                listaId: this.estrategia.listaId,
                query
            }
        });
    }

    abrirModal() {
        // campo dos radios
        if (this.estrategia.recorrente == false) {
            this.estrategia.dataInicio = null;
            this.estrategia.horaInicio = null;
            this.estrategia.dataTermino = null;
            this.estrategia.horaTermino = null;
            this.estrategia.intervalo = null;
            this.estrategia.diaSemana = null;
            return;
        } else {
            if (!this.isEdit)
                this.estrategia.envioImediato = true;
        }

        var agendamentoAnterior = {
            dataInicio: this.estrategia.dataInicio,
            horaInicio: this.estrategia.horaInicio,
            dataTermino: this.estrategia.dataTermino,
            horaTermino: this.estrategia.horaTermino,
            intervalo: this.estrategia.intervalo,
            diaSemana: this.estrategia.diaSemana
        };
        const agendamentoRef = this.dialog.open(AgendamentoEstrategiaComponent, {
            width: "800px",
            height: "720px",
            data: {
                estrategia: this.estrategia,
                dirty: this.dirty
            }
        });

        agendamentoRef.afterClosed().subscribe((estrategia: Estrategia) => {
            this.dirty = true;
            if (estrategia) return;
            Object.assign(this.estrategia, agendamentoAnterior);
        });
    }

    setQuantidadeLista(listaId: any, aplicarESalvar: boolean = false) {

        if (listaId === undefined) {
            let nome = this.estrategia.nome;
            this.estrategia = new Estrategia();
            this.estrategia.nome = nome;
            this.elasticsearchFilter.must = [];
            this.query = {};
            return;
        }

        this.obterProvedores(listaId);
        this.listaService
            .obterQuantidadeLista(listaId, this.estrategia.listaEstrategiaId)
            .subscribe(
                quantidades => {
                    this.quantidadeEstrategia = quantidades.quantidadeLivre;
                    this.dadosQuantidadeAlterada.totalParaUtilizar = this.quantidadeEstrategia;

                    this.calcularQuantidadesProvedores(aplicarESalvar);
                }
            );
    }

    updateQuantidades(event: any) {
        let quantidades: Array<number> = event.quantidade;
        let aplicarESalvar: boolean = event.aplicarESalvar;

        if (undefined != quantidades && quantidades.length != 0) {
            this.quantidadeEstrategia = quantidades[quantidades.length - 1];
            this.dadosQuantidadeAlterada.totalParaUtilizar = this.quantidadeEstrategia;
            this.calcularQuantidadesProvedores(aplicarESalvar);

            return;
        }

        this.quantidadeEstrategia = 0;
        this.dadosQuantidadeAlterada.totalParaUtilizar = 0;

        if (undefined == this.estrategia.listaId) return;
        this.setQuantidadeLista(this.estrategia.listaId, aplicarESalvar);


    }

    criarEstrategia(aplicarESalvar: boolean = false) {

        this.dirty = true;
        this.estrategia.estrategiaAcaoCampanhaTipoId = EstrategiaAcaoCampanhaTipo.ImportarCampanha;

        if (this.estrategia.validar() || this.errosEstrategiaFilha.some((f: any) => Object.keys(f).length > 1)) {
            this.customAlertService.show("telaEstrategia.estrategia", "telaPadrao.camposInvalidos", "error");
            return;
        }

        if (this.estrategiasFilhas.some((f: EstrategiasFilhas) =>
            f.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.Multipla && f.quantidadeMaximaExportacao == 0))
            return this.customAlertService.show("telaEstrategia.estrategia", "telaEstrategia.provedorZerado", "error");


        this.estrategia.filtroAlias = this.elasticsearchFilter.must
            .filter(c => c["not_in"])
            .map(c => c.property)
            .join(",");

        this.estrategia.filtro = JSON.stringify(this.elasticsearchFilter.query);

        const requisicao = !this.estrategia.listaEstrategiaId
            ? this.estrategiaService.criarEstrategia(this.estrategia)
            : this.estrategiaService.atualizarEstrategia(this.estrategia);

        requisicao.subscribe(
            () => {
                this.customAlertService.show("telaEstrategia.estrategia", "telaEstrategia.salvarEstrategia", "success");

                if (aplicarESalvar === true)
                    return;

                if (!isNullOrEmpty(this.estrategia.reguaId))
                    this.router.navigate([`/app/regua/${this.estrategia.reguaId}`]);

                else if (!isNullOrEmpty(this.estrategia.fluxoId))
                    this.router.navigate([`/app/fluxo/${this.estrategia.fluxoId}`]);

                else
                    this.router.navigate(["/app/lista", this.estrategia.listaId, "estrategia"]);

            },
            (error: any) => {
                this.customAlertService.show("telaEstrategia.estrategia", error.error[0], "error");
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    public get errosEstrategiaFilha() {
        if (!this.dirty) return;

        let erros: any[] = [];

        for (let i = 0; i < this.estrategiasFilhas.length; i++) {
            let e: EstrategiasFilhas = EstrategiasFilhas.fromRaw(this.estrategiasFilhas[i]);
            erros.push(Object.assign({}, { 'index': i }, e.validar()));
        }

        return erros;
    }

    liveError(index: number, property: string) {
        if (!this.dirty) return;

        let erros: any[] = [];

        for (let i = 0; i < this.estrategiasFilhas.length; i++) {
            let e: EstrategiasFilhas = EstrategiasFilhas.fromRaw(this.estrategiasFilhas[i]);
            erros.push(Object.assign({}, { 'index': i }, e.validar()));
        }
        if (erros[index]) return null;
        return erros[index][property] ? erros[index][property][0] : null;
    }


    quantidadeListaAlterada(dadosQuantidadeAlterada: EstrategiaTotal) {
        this.dadosQuantidadeAlterada = dadosQuantidadeAlterada;
        this.dadosQuantidadeAlterada.totalParaUtilizar = dadosQuantidadeAlterada.quantidadeEstrategia;
        this.quantidadeEstrategia = dadosQuantidadeAlterada.quantidadeEstrategia;
        this.calcularQuantidadesProvedores();
    }

    adicionarEstrategiaFilha() {
        var medidaMaximaExportacaoPctAnterior = this.estrategia.medidaMaximaExportacaoPct;
        this.estrategia.medidaMaximaExportacaoPct = true;
        if (!medidaMaximaExportacaoPctAnterior) this.medidaChange();
        this.estrategia.estrategiasFilhas.push(new EstrategiasFilhas(EstrategiaAcaoCampanhaTipo.Multipla, true, this.estrategia.medidaMaximaExportacaoPct));
    }

    medidaChange() {
        var estrategia = this.estrategiasFilhas[0];
        estrategia.medidaMaximaExportacaoPct = this.estrategia.medidaMaximaExportacaoPct;

        if (this.estrategia.medidaMaximaExportacaoPct) {
            if (isNullOrZero(estrategia.quantidadeMaximaExportacaoPctSemPerda))
                estrategia.quantidadeMaximaExportacaoPctSemPerda = estrategia.quantidadeMaximaExportacaoPct;

            estrategia.quantidadeMaximaExportacaoPct = Math.floor(estrategia.quantidadeMaximaExportacaoPctSemPerda);

            if (this.dadosQuantidadeAlterada.totalParaUtilizar > 0)
                estrategia.quantidadeMaximaExportacao = Math.round(((this.dadosQuantidadeAlterada.totalParaUtilizar * estrategia.quantidadeMaximaExportacaoPctSemPerda) / 100));
        } else {
            if (this.dadosQuantidadeAlterada.totalParaUtilizar > 0)
                estrategia.quantidadeMaximaExportacaoPct = ((estrategia.quantidadeMaximaExportacao * 100) / this.dadosQuantidadeAlterada.totalParaUtilizar);
        }
    }

    estrategiaFilhaCalculoChange(estrategiafilha: EstrategiasFilhas) {
        this.estrategiaFilhaCalculo(estrategiafilha);
    }

    estrategiaFilhaCalculo(estrategiafilha: EstrategiasFilhas) {
        if (estrategiafilha.quantidadeMaximaExportacao > this.dadosQuantidadeAlterada.totalParaUtilizar) {
            estrategiafilha.quantidadeMaximaExportacao = this.dadosQuantidadeAlterada.totalParaUtilizar;
            this.customAlertService.show("telaEstrategia.estrategia", `A quantidade máxima não pode ser superior a ${estrategiafilha.quantidadeMaximaExportacao}.`, "error");
        }

        if (this.dadosQuantidadeAlterada.totalParaUtilizar > 0) {
            estrategiafilha.quantidadeMaximaExportacaoPct = ((estrategiafilha.quantidadeMaximaExportacao * 100) / this.dadosQuantidadeAlterada.totalParaUtilizar);
            estrategiafilha.quantidadeMaximaExportacaoPctSemPerda = estrategiafilha.quantidadeMaximaExportacaoPct;
        }
        else
            estrategiafilha.quantidadeMaximaExportacaoPct = 0;
    }

    estrategiaFilhaCalculoChangePct(estrategiafilha: EstrategiasFilhas) {
        this.estrategiaFilhaCalculoPct(estrategiafilha);
    }

    estrategiaFilhaCalculoPct(estrategiafilha: EstrategiasFilhas) {
        this.dadosQuantidadeAlterada.totalParaUtilizarPct = 100;

        let total = this.estrategiasFilhas.filter(f => f.ativo).map(m => m.quantidadeMaximaExportacaoPct).reduce((item1, item2) => (item1 + item2));

        if (total > this.dadosQuantidadeAlterada.totalParaUtilizarPct) {
            estrategiafilha.quantidadeMaximaExportacaoPct = this.dadosQuantidadeAlterada.totalParaUtilizarPct - (total - estrategiafilha.quantidadeMaximaExportacaoPct);
            this.customAlertService.show("telaEstrategia.estrategia", `A quantidade máxima não pode ser superior a 100%.`, "error");
        }

        this.calcularQuantidadesProvedores();
    }

    calcularQuantidadesProvedores(aplicarESalvar: boolean = false) {



        let estrategiaIndividual = this.estrategiasFilhas.length == 1 ? this.estrategiasFilhas[0] : null;

        if (estrategiaIndividual) {
            if (estrategiaIndividual.medidaMaximaExportacaoPct) {
                estrategiaIndividual.quantidadeMaximaExportacao = Math.round((this.dadosQuantidadeAlterada.totalParaUtilizar * estrategiaIndividual.quantidadeMaximaExportacaoPct) / 100);
                estrategiaIndividual.quantidadeMaximaExportacaoPctSemPerda = estrategiaIndividual.quantidadeMaximaExportacaoPct;
            }
            else {
                if (this.dadosQuantidadeAlterada.totalParaUtilizar < estrategiaIndividual.quantidadeMaximaExportacao) {
                    estrategiaIndividual.quantidadeMaximaExportacao = this.dadosQuantidadeAlterada.totalParaUtilizar;
                    estrategiaIndividual.quantidadeMaximaExportacaoPct = this.dadosQuantidadeAlterada.totalParaUtilizar == 0 ? 0 : 100;
                    estrategiaIndividual.quantidadeMaximaExportacaoPctSemPerda = estrategiaIndividual.quantidadeMaximaExportacaoPct;
                }
                else {
                    estrategiaIndividual.quantidadeMaximaExportacaoPct = ((estrategiaIndividual.quantidadeMaximaExportacao * 100) / this.dadosQuantidadeAlterada.totalParaUtilizar);
                    estrategiaIndividual.quantidadeMaximaExportacaoPctSemPerda = estrategiaIndividual.quantidadeMaximaExportacaoPct;
                }
            }

        } else {

            let quantidadeRestante = this.dadosQuantidadeAlterada.totalParaUtilizar;
            let estrategiasFilhas = [...this.estrategiasFilhas];
            estrategiasFilhas = estrategiasFilhas.sort((a, b) => b.quantidadeMaximaExportacaoPct - a.quantidadeMaximaExportacaoPct);

            estrategiasFilhas.forEach(estrategia => {
                estrategia.quantidadeMaximaExportacao = Math.round((this.dadosQuantidadeAlterada.totalParaUtilizar * estrategia.quantidadeMaximaExportacaoPct) / 100);
                estrategia.quantidadeMaximaExportacao = estrategia.quantidadeMaximaExportacao > quantidadeRestante ? quantidadeRestante : estrategia.quantidadeMaximaExportacao;
                quantidadeRestante -= estrategia.quantidadeMaximaExportacao;
            });

            if (quantidadeRestante > 0 && !this.quantidadeComFilhasMenorQueCemPorcento()) {
                var estrategiaMaiorPercentual = estrategiasFilhas[0];
                estrategiaMaiorPercentual.quantidadeMaximaExportacao += quantidadeRestante;
            }
        }

        if (aplicarESalvar === true)
            this.criarEstrategia(true);

        return;
    }

    excluirEstrategiaFilha(estrategiafilha: EstrategiasFilhas) {
        if (estrategiafilha.listaEstrategiaId == 0) {
            this.estrategia.estrategiasFilhas.splice(this.estrategia.estrategiasFilhas.indexOf(estrategiafilha), 1);
        }
        else {
            estrategiafilha.ativo = false;
            estrategiafilha.quantidadeMaximaExportacao = 0;
            estrategiafilha.quantidadeMaximaExportacaoPct = 0;
        }

        this.calcularQuantidadesProvedores();
    }

    quantidadeComFilhasMenorQueCemPorcento() {
        if (this.estrategiasFilhas.length > 1) {
            var total = this.estrategiasFilhas.filter(f => f.ativo).map(m => m.quantidadeMaximaExportacaoPct).reduce((item1, item2) => (item1 + item2))

            if (total < 100)
                return true;
        }

        return false;
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    alterarReenviarDistribuido(valor) {
        this.estrategia.reenviarDistribuido = valor;
    }

    abrirOrdenacao() {
        const ref = this.dialog.open(ElasticsearchAddOrderComponent, {
            hasBackdrop: true,
            width: "65%",
            height: "710px",
            data: {
                templateId: this.templateId,
                lista: this.nomeLista,
                query: this.query,
                listaEstrategiaPrioridade: this.estrategia.listaEstrategiaPrioridade,
                reenviarDistribuido: this.estrategia.reenviarDistribuido,
                listaId: this.estrategia.listaId
            }
        });

        ref.afterClosed().subscribe(result => {
            if (!result) return;
            this.estrategia.listaEstrategiaPrioridade = result;
        });
    }

    obterCaminhoDestinoTemplate() {
        if (isNullOrEmpty(this.estrategia.listaTemplateExportacaoId))
            return "";

        return this.listaTemplateExportacaoSelect.find(f => f.listaTemplateId == this.estrategia.listaTemplateExportacaoId.toString()).caminhoDestino;
    }

    verificaDiretorioTemplateVirtual() {
        let caminho = this.obterCaminhoDestinoTemplate();
        var validRegex = /^(\\\\[^/\\\]\[":;|<>+=,?* _]+\\[^/\\\]\[":;|<>+=,?*]+)((?:\\[^\\/:*?"<>|]+)*\\?)$/i
        return validRegex.test(caminho);
    }



    voltar() {
        this._location.back();
    }

    verificarTemplateCampoRetornoInvalido(estrategiaFilha: EstrategiasFilhas): boolean {
        if (estrategiaFilha.listaTemplateExportacaoSelect == null)
            return false;

        var templateSelecionado = estrategiaFilha.listaTemplateExportacaoSelect.find(t => t.listaTemplateId == estrategiaFilha.listaTemplateExportacaoId);

        return templateSelecionado == null ? false : (templateSelecionado.possuiCampoRetornoInvalido == null ? false : templateSelecionado.possuiCampoRetornoInvalido);
    }

    desabilitarBotaoGravar() {
        if (!this.nomeLista || !this.estrategia.nome)
            return true;

        return this.quantidadeComFilhasMenorQueCemPorcento();
    }

    //#region [ FiltroQuery ]

    public get obterNomeFiltroPadrao() {
        if (isNull(this.estrategia.filtroQuery))
            return '---';

        return this.estrategia.filtroQuery.nome;
    }

    modalFiltroPadrao() {
        const dialogRef = this.dialog.open(FiltroQueryPadraoModalComponent, {
            hasBackdrop: true,
            width: "800px",
            height: "720px",
            data: {
                listaId: this.estrategia.listaId,
                filtroQueryId: this.estrategia.filtroQueryId
            }
        });

        dialogRef.afterClosed().subscribe((filtroQuery: FiltroQuery) => {
            if (isNull(filtroQuery))
                return;

            let json = JSON.parse(filtroQuery.filtro);

            switch (this.estrategia.reenviarDistribuido) {
                case FiltroStatusRegistroLista.naoUtilizado:
                    json.query.bool.must_not.push({ "exists": { "field": "_integrado" } });
                    break;
                case FiltroStatusRegistroLista.total:
                    break;
                case FiltroStatusRegistroLista.utilizado:
                    json.query.bool.must.Add({ "exists": { "field": "_integrado" } });
                    break;
            }
            let estrategia = Estrategia.fromRaw(this.estrategia);
            estrategia.filtroQueryId = filtroQuery.filtroQueryId;
            estrategia.filtroQuery = filtroQuery;
            estrategia.filtro = JSON.stringify(json);
            estrategia.filtroRegras = filtroQuery.filtroRegras;
            this.estrategia = estrategia;
        });
    }


    aplicarFiltroPadrao() {
        let filtroQuery = this.estrategia.filtroQuery;
        let json = JSON.parse(filtroQuery.filtro);

        switch (this.estrategia.reenviarDistribuido) {
            case FiltroStatusRegistroLista.naoUtilizado:
                json.query.bool.must_not.push({ "exists": { "field": "_integrado" } });
                break;
            case FiltroStatusRegistroLista.total:
                break;
            case FiltroStatusRegistroLista.utilizado:
                json.Query.Bool.Must.Add({ "exists": { "field": "_integrado" } });
                break;
        }
        let estrategia = Estrategia.fromRaw(this.estrategia);
        estrategia.filtroQueryId = filtroQuery.filtroQueryId;
        estrategia.filtroQuery = filtroQuery;
        estrategia.filtro = JSON.stringify(json);
        estrategia.filtroRegras = filtroQuery.filtroRegras;
        this.estrategia = estrategia;
    }

    public get temFiltroPadrao() {
        return (!isNull(this.estrategia.filtroQueryId))
    }

    public get mostrarBotaoAplicar() {
        if (this.temFiltroPadrao == false)
            return false;

        let filtroQuery = this.estrategia.filtroQuery;
        let json = JSON.parse(filtroQuery.filtro);

        switch (this.estrategia.reenviarDistribuido) {
            case FiltroStatusRegistroLista.naoUtilizado:
                json.query.bool.must_not.push({ "exists": { "field": "_integrado" } });
                break;
            case FiltroStatusRegistroLista.total:
                break;
            case FiltroStatusRegistroLista.utilizado:
                json.Query.Bool.Must.Add({ "exists": { "field": "_integrado" } });
                break;
        }

        return (JSON.stringify(json) != JSON.stringify(this.elasticsearchFilter.query));
    }

    desvincularFiltroPadrao() {

        this.customAlertService
            .showWithConfiguration({
                title: "telaEstrategia.estrategia",
                text: "telaFiltrosPadroes.msgConfirmDesvincular",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "telaPadrao.sim",
                cancelButtonText: "telaPadrao.nao"
            })
            .then(data => {

                if (data.isConfirmed) {

                    let json = JSON.parse('{"size":"10","_source":{"includes":[]},"query":{"bool":{"must":[],"must_not":[]}}}');

                    switch (this.estrategia.reenviarDistribuido) {
                        case FiltroStatusRegistroLista.naoUtilizado:
                            json.query.bool.must_not.push({ "exists": { "field": "_integrado" } });
                            break;
                        case FiltroStatusRegistroLista.total:
                            break;
                        case FiltroStatusRegistroLista.utilizado:
                            json.Query.Bool.Must.Add({ "exists": { "field": "_integrado" } });
                            break;
                    }
                    let estrategia = Estrategia.fromRaw(this.estrategia);
                    estrategia.filtroQueryId = null;
                    estrategia.filtroQuery = null;
                    estrategia.filtro = JSON.stringify(json);
                    estrategia.filtroRegras = "[]";
                    this.estrategia = estrategia;
                }
            });
    }

    //#endregion

}