export class ListaTemplateExportacaoParametrosUrl {
    public listaTemplateExportacaoParametrosUrlId: number;
    public listaTemplateId: number;
    public parametro: string;
    public listaTemplateItemId: number;
    public ativo: boolean;

    constructor(
        listaTemplateExportacaoParametrosUrlId: number = null,
        listaTemplateId: number = null,
        parametro: string = null,
        listaTemplateItemId: number = null,
        ativo: boolean = true
    ) {
        this.listaTemplateExportacaoParametrosUrlId = listaTemplateExportacaoParametrosUrlId;
        this.listaTemplateId = listaTemplateId;
        this.parametro = parametro;
        this.listaTemplateItemId = listaTemplateItemId;
        this.ativo = ativo;
    }

    static fromRaw(raw: any): ListaTemplateExportacaoParametrosUrl {
        return Object.assign(new ListaTemplateExportacaoParametrosUrl(), raw);
    }
}
