import { isNull, isNullOrEmpty } from "../utils";
import { CampoIntegracao } from "./campo-integracao";
import { ListaCampoValidacao } from "./lista-campo-validacao";
import { ListaTipoCampoFormatacao, ListaTipoCampoFormatacaoLocal } from "./lista-tipo-campo-formatacao";
import { TemplateItemListaValor } from "./template-item-lista-valor";

export enum ItemTipo {
	Padrao = 1,
	Linha = 2,
	Coluna = 3,
	Score = 4,
	Sistema = 5,
	Retorno = 6,
	Validacao = 7,
	Tracking = 8,
	Conversao = 9
}
export class ListaTemplateItem {
	public listaTemplateId: number;
	public listaTemplateItemId: number;
	public listaCampoTipoId: number;

	public nome: string;
	public alias: string;
	public ordem: number;
	public tipoCampoExportacao: number;
	public conteudoCustomizado: string;
	public dataCadastro: Date;
	public ativo: boolean;
	public tamanho: number;
	public editable: boolean;
	//public editarLinha: boolean;
	public virtualId: number;

	public listaCampoTipoId_Nested: number;
	public alias_Nested: string;
	public lista_Nested: boolean;

	public listaTemplateItemQuebraId: number;
	public itemTipo: ItemTipo;
	public quebraLinhaNumero: number;
	public guidQuebraColuna: string;
	public configuracaoCampoProviderId: number;

	public tipoCampoImportacao: number;
	public conteudo: string = "";
	public possuiLookup: boolean;

	public templateItemListaValor: Array<TemplateItemListaValor>;

	public listaCampoValidacaoId: number;
	public chaveValidacao: string;

	private _linhaColuna: string;
	public get linhaColuna(): string { return this._linhaColuna; }

	public set linhaColuna(valor: string) {
		this._linhaColuna = valor;

		if ((valor == undefined || valor == null || valor == '')) {
			this.quebraLinhaNumero = null;
			this.guidQuebraColuna = '';
		} else {
			let sp = valor.split(':');
			this.quebraLinhaNumero = parseInt(sp[0].substr(1, sp[0].length - 1));
			this.guidQuebraColuna = sp[1].substr(1, sp[1].length - 1);
		}
	}



	public templateColunaQuebraId: string;
	public listaLinhasColunas: Array<any>;

	//#region [ Campos que não existem na API]

	// Esse campo so é usado para voltar ao status anterior da seleção de uma quebra de linha, ele não existe no banco de dados e nem na API/BACKEND
	public listaCampoTipoIdAnteriorQuebraLinha: number;
	public listaCampoValidacaoIdAnteriorQuebraLinha: number;
	public chaveValidacaoAnteriorQuebraLinha: string;
	public conteudoAnteriorQuebraLinha: string;
	public tipoCampoImportacaoAnteriorQuebraLinha: number;
	public listaCampoTipo: CampoIntegracao;
	public listaCampoTipoFormatacao: Array<ListaTipoCampoFormatacao> = [];

	public get conteudoFormatacoesFuncoes(): Array<string> {
		if (isNullOrEmpty(this.conteudo))
			return [];

		let formatos = this.conteudo.match(/\.([a-zA-Z]*)\(/g);

		if (isNull(formatos))
			return [];

		return formatos.map((m: string) => m.replace('.', '').replace('(', ''));
	}

	public get conteudoFormatacoesNumericas(): Array<string> {
		if (isNullOrEmpty(this.conteudo))
			return [];

		let formatos = this.conteudo.match(/\|([a-zA-Z|\d]{2})\v/g);

		if (isNull(formatos))
			return [];

		return formatos.map((m: string) => m.replace('|', ''))
	}

	public get conteudoFormatacoesDatas(): Array<string> {
		if (isNullOrEmpty(this.conteudo))
			return [];

		let formatos = this.conteudo.match(/\|(([dMy]*)[- /.]([Md]*)[- /.]([y]*)|([dMy]*)[- /.]([Mdy]*))[- /.]([H]*)[- :][m]*|\|(([dMy]*)[- /.]([Mdy]*)[- /.]([H]*)[- :][m]*)|\|(([dMy]*)[- /.]([Md]*)[- /.]([y]*)|([dMy]*)[- /.]([Mdy]*))/g);

		if (isNull(formatos))
			return [];

		return formatos.map((m: string) => m.replace('|', ''));
	}

	public get formatos() {
		let f = this.listaCampoTipoFormatacao.filter((f: ListaTipoCampoFormatacao) => (f.local == ListaTipoCampoFormatacaoLocal.Ambos || f.local == ListaTipoCampoFormatacaoLocal.Importacao));
		let a: Array<string> = ['F2', 'F1', 'N2', 'N1', 'C2'];
		let b: Array<string> = ['Telefone', 'Tratar Telefone', 'DDD', 'DDI'];
		let c: any = { 'DDI': '55', 'DDD': '11', 'Telefone': '980808080', 'Tratar Telefone': '11980808080' };

		return f.map((m: ListaTipoCampoFormatacao) => {
			if (a.some((s: string) => s == m.formato))
				m.label = `${m.formato} - Ex: ${m.exemplo}`;
			else if (b.some((s: string) => s == m.formato))
				m.label = `${m.formato} - Ex: ${c[m.formato]}`;
			else
				m.label = m.formato;
			return m;
		});
	}

	public get permiteFormatacao(): boolean {
		return (this.formatos.length > 0);
	}


	public listaCamposValidacao: Array<ListaCampoValidacao> = [];

	public get validacoes() {
		return this.listaCamposValidacao;
	}

	public get permiteValidacao(): boolean {
		return (this.validacoes.length > 0);
	}

	//#endregion

	constructor(nome?: string, campoIntegracao?: CampoIntegracao, ordem?: number, tamanho: number = 0, editable: boolean = false) {
		this.alias = (campoIntegracao) ? campoIntegracao.nome : undefined;
		this.listaCampoTipoId = (campoIntegracao) ? campoIntegracao.listaCampoTipoId : undefined;
		this.tamanho = tamanho;
		this.ordem = ordem;
		this.nome = (nome) ? nome.replace("\r", "").replace("\n", "") : undefined;
		this.ativo = true;
		this.editable = editable;
		this.templateItemListaValor = new Array<TemplateItemListaValor>();
	}

	public setLinhaColuna() {
		if ((this.guidQuebraColuna != undefined && this.guidQuebraColuna != null && this.guidQuebraColuna != '') && (this.quebraLinhaNumero != undefined && this.quebraLinhaNumero != null))
			this.linhaColuna = `L${this.quebraLinhaNumero}:C${this.guidQuebraColuna}`;
	}

	static fromRaw(raw): ListaTemplateItem {
		return Object.assign(new ListaTemplateItem(null, null, null, null), raw);
	}
}