import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ElasticsearchRepositoryService } from "../../repository/elasticsearch-repository.service";

@Component({
  selector: "app-quantidade-filtro-lista",
  templateUrl: "./quantidade-filtro-lista.component.html"
})
export class QuantidadeFiltroListaComponent implements OnInit {


  @Input()
  public set atualizar(val: any) {
    this._atualizar = val;
  }

  public get atualizar() {
    return {
      atualizar: this._atualizar
    };
  }

  @Output()
  preview: EventEmitter<{}>;

  @Input()
  get encontrado() {
    return this._encontrado;
  }

  @Output() encontradoChange = new EventEmitter();
  set encontrado(val) {
    this._encontrado = val;
    this.encontradoChange.emit(this.encontrado);
  }

  @Input()
  get disponivel() {
    return this._disponivel;
  }

  @Output() disponivelChange = new EventEmitter();
  set disponivel(val: any) {
    this._disponivel = val;
    this.disponivelChange.emit(this.disponivel);
  }

  _disponivel: number;
  _encontrado: number;
  _atualizar: boolean = false;

  _query: any;
  @Input()
  index;

  @Input()
  public set query(query: any) {
    this._query = query;
    this.elasticsearchRepositoryService
      .getQuantidadeFiltros(this.index, this.query)
      .subscribe(
        quantidades =>

          (this.encontrado = quantidades.length == 0 ? this._disponivel : quantidades[quantidades.length - 1],
            this.disponivel = !this._atualizar ? this._disponivel : this._encontrado

          ));

  }
  public get query() {
    return {
      query: this._query
    };
  }

  constructor(
    private elasticsearchRepositoryService: ElasticsearchRepositoryService
  ) { 
    this.preview = new EventEmitter<{}>();
  }

  emitPreview() {
    this.preview.emit();
  }

  ngOnInit() { }
}
