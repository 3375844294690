import { StorageProxy } from "../service/storage-proxy.service";
import { isNullOrEmpty } from "../utils";

export class Permissao {
	permissaoRecurso: Array<PermissaoRecurso>;
	permissaoCarteira: Array<PermissaoCarteira>;
	permissaoLista: Array<PermissaoLista>;
}

export class PermissaoRecurso {
	modulo: string;
	acao: string;
}

export class PermissaoCarteira {
	carteiraId: number;
	nome: string;
}

export class PermissaoLista {
	listaId: number;
}

export class PermissoesUsuario {

	//#region [ Recurso ]

	public static get recursos(): Array<PermissaoRecurso> {
		let retorno = StorageProxy.recursos;
		if (isNullOrEmpty(retorno)) return [];
		return StorageProxy.recursos;
	};

	public static set recursos(v: Array<PermissaoRecurso>) {
		StorageProxy.recursos = v;
	};

	public static possuiPermissao(modulo: string, acao: string): boolean {

		return (
			PermissoesUsuario.recursos.find(
				l => l.acao == acao && l.modulo == modulo
			) != undefined
		);
	}

	//#endregion

	//#region [ Carteira ]

	public static get carteiras(): Array<PermissaoCarteira> {
		let retorno = StorageProxy.carteiras;
		if (isNullOrEmpty(retorno)) return [];
		return StorageProxy.carteiras;
	};

	public static set carteiras(v: Array<PermissaoCarteira>) {
		StorageProxy.carteiras = v;
	};

	//#endregion

	//#region [ Lista ]

	public static get listas(): Array<PermissaoLista> {
		let retorno = StorageProxy.listas;
		if (isNullOrEmpty(retorno)) return [];
		return StorageProxy.listas;
	};

	public static set listas(v: Array<PermissaoLista>) {
		StorageProxy.listas = v;
	};


	//#endregion	
}