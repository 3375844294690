import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ListaStatus } from "../model/listaStatus";
import { OrderOperation } from "../../elasticsearch/model/ordertypes";
import { TipoCategoria } from "../model/template";
import { LayoutMenu } from "../../../common/model/layout-menu";

@Injectable()
export class DefaultValuesService {
	async obterCategoriaTemplate() {
		return [
			{
				descricao: await this.translateService
					.get("telaTemplate.templateImportacao")
					.toPromise(),
				templateCategoriaId: TipoCategoria.importacao
			},
			{
				descricao: await this.translateService
					.get("telaTemplate.templateExportacao")
					.toPromise(),
				templateCategoriaId: TipoCategoria.exportacao
			}
		];
	}
	testeBoolean: any[];

	constructor(private translateService: TranslateService) { }

	async obterGrantType() {
		//client_credentials
		//password
		return [
			{
				label: await this.translateService
					.get("oauthGrantType.client_credentials")
					.toPromise(),
				value: "client_credentials"
			},
			{
				label: await this.translateService
					.get("oauthGrantType.password")
					.toPromise(),
				value: "password"
			}
		];
	}

	async obterBoolean() {
		return [
			{
				label: await this.translateService.get("telaPadrao.sim").toPromise(),
				value: true
			},
			{
				label: await this.translateService.get("telaPadrao.nao").toPromise(),
				value: false
			}
		];
	}

	async obterMenu() {

		return [
			{
				'nome': await this.translateService.get("menu.home").toPromise(),
				'icone': 'home',
				'rota': '/app/home',
				'recurso': ['Dashboard'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': false,
				'subMenus': []
			},
			{
				'nome': await this.translateService.get("menu.dash").toPromise(), 
				'icone': 'insert_chart',
				'rota': '',
				'recurso': ['Dashboard', 'DashboardEstrategias', 'DashboardIndividual'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': true,
				'subMenus': [
					{
						'nome': await this.translateService.get("menu.dashListaProvedor").toPromise(),
						'icone': 'line_weight',
						'rota': '/app/dashboard/lista-provedor',
						'recurso': ['Dashboard'],
						'recursoAcao': 'Leitura',
						'acao': null,
					},
					{
						'nome': await this.translateService.get("menu.dashEstrategias").toPromise(),
						'icone': 'show_chart',
						'rota': '/app/dashboard/estrategias',
						'recurso': ['DashboardEstrategias'],
						'recursoAcao': 'Leitura',
						'acao': null,
					},
					{
						'nome': await this.translateService.get("menu.dashIndividual").toPromise(),
						'icone': 'people_alt',
						'rota': '/app/dashboard/individual',
						'recurso': ['DashboardIndividual'],
						'recursoAcao': 'Leitura',
						'acao': null,
					}
				]
			},
			{
				'nome': await this.translateService.get("menu.carteira").toPromise(),
				'icone': 'work',
				'rota': '/app/carteira',
				'recurso': ['Carteira'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': false,
				'subMenus': []
			},
			{
				'nome': await this.translateService.get("menu.usuario").toPromise(),
				'icone': 'person',
				'rota': '/app/usuario',
				'recurso': ['Usuario'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': false,
				'subMenus': []
			},
			{
				'nome': await this.translateService.get("menu.template").toPromise(),
				'icone': 'settings',
				'rota': '/app/template',
				'recurso': ['Template'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': false,
				'subMenus': []
			},
			{
				'nome': await this.translateService.get("menu.lista").toPromise(),
				'icone': 'grid_on',
				'rota': '',
				'recurso': ['Lista'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': true,
				'subMenus': [
					{
						'nome': await this.translateService.get("menu.listaGerenciar").toPromise(),
						'icone': 'storage',
						'rota': '/app/lista',
						'recurso': ['Lista'],
						'recursoAcao': 'Leitura',
						'acao': null,
					},
					{
						'nome': await this.translateService.get("menu.listaDashboard").toPromise(),
						'icone': 'hourglass_empty',
						'rota': '/app/lista/dashboard',
						'recurso': ['Lista'],
						'recursoAcao': 'Leitura',
						'acao': null,
					}
				]
			},
			{
				'nome': await this.translateService.get("menu.estrategia").toPromise(),
				'icone': 'business',
				'rota': '',
				'recurso': ['Estrategia', 'Tarefa', 'EstrategiaRemocao', 'FiltroQuery'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': true,
				'subMenus': [
					{
						'nome': await this.translateService.get("menu.estrategiaImportacao").toPromise(),
						'icone': 'filter_list',
						'rota': '/app/estrategia',
						'recurso': ['Estrategia'],
						'recursoAcao': 'Leitura',
						'acao': null,
					},
					{
						'nome': await this.translateService.get("menu.execucaoEstrategia").toPromise(),
						'icone': 'history',
						'rota': '/app/tarefa',
						'recurso': ['Tarefa'],
						'recursoAcao': 'Leitura',
						'acao': null,
					},
					{
						'nome': await this.translateService.get("menu.estrategiaRemocao").toPromise(),
						'icone': 'delete_sweep',
						'rota': '/app/estrategiaremocao',
						'recurso': ['EstrategiaRemocao'],
						'recursoAcao': 'Leitura',
						'acao': null,
					},
					{
						'nome': await this.translateService.get("menu.filtrosPadroes").toPromise(),
						'icone': 'bookmarks',
						'rota': '/app/filtroquery',
						'recurso': ['FiltroQuery'],
						'recursoAcao': 'Leitura',
						'acao': null,
					}
				]
			},
			{
				'nome': await this.translateService.get("menu.regua").toPromise(),
				'icone': 'linear_scale',
				'rota': '/app/regua',
				'recurso': ['Regua'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': false,
				'subMenus': []
			},
			{
				'nome': await this.translateService.get("menu.fluxo").toPromise(),
				'icone': 'device_hub',
				'rota': '/app/fluxo',
				'recurso': ['Fluxo'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': false,
				'subMenus': []
			},
			{
				'nome': await this.translateService.get("menu.provedor").toPromise(),
				'icone': 'assignment_ind',
				'rota': '/app/provedor',
				'recurso': ['Provedor'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': false,
				'subMenus': []
			},
			{
				'nome': await this.translateService.get("menu.pesquisa").toPromise(),
				'icone': 'search',
				'rota': '',
				'recurso': ['PesquisaEstrategia', 'PesquisaLista'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': true,
				'subMenus': [
					{
						'nome': await this.translateService.get("menu.pesquisaEstrategia").toPromise(),
						'icone': 'find_in_page',
						'rota': '/app/pesquisaestrategia',
						'recurso': ['PesquisaEstrategia'],
						'recursoAcao': 'Leitura',
						'acao': null,
					},
					{
						'nome': await this.translateService.get("menu.pesquisaLista").toPromise(),
						'icone': 'pageview',
						'rota': '/app/pesquisalista',
						'recurso': ['PesquisaLista'],
						'recursoAcao': 'Leitura',
						'acao': null,
					}
				]
			},
			{
				'nome': await this.translateService.get("menu.retorno").toPromise(),
				'icone': 'reply',
				'rota': '',
				'recurso': ['HistoricoRetorno'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': true,
				'subMenus': [
					{
						'nome': await this.translateService.get("menu.historicoRetorno").toPromise(),
						'icone': 'history',
						'rota': '/app/historicoretorno',
						'recurso': ['HistoricoRetorno'],
						'recursoAcao': 'Leitura',
						'acao': null,
					},
					{
						'nome': await this.translateService.get("menu.monitorRetorno").toPromise(),
						'icone': 'assignment_return',
						'rota': '/app/monitorretorno',
						'recurso': ['MonitorRetorno'],
						'recursoAcao': 'Leitura',
						'acao': null,
					}
				]
			},
			{
				'nome': await this.translateService.get("menu.sistema").toPromise(),
				'icone': 'computer',
				'rota': '',
				'recurso': ['Configuracao'],
				'recursoAcao': 'Leitura',
				'acao': null,
				'hasSubMenu': true,
				'subMenus': [
					{
						'nome': await this.translateService.get("menu.configuracao").toPromise(),
						'icone': 'build',
						'rota': '/app/configuracao',
						'recurso': ['Configuracao'],
						'recursoAcao': 'Leitura',
						'acao': null,
					},
					{
						'nome': await this.translateService.get("menu.downloadRegistro").toPromise(),
						'icone': 'file_download',
						'rota': '/app/downloadregistro',
						'recurso': ['DownloadRegistro'],
						'recursoAcao': 'Leitura',
						'acao': null,
					},
					{
						'nome': await this.translateService.get("menu.documentacao").toPromise(),
						'icone': 'help',
						'rota': '/app/documentacao',
						'recurso': ['Documentacao'],
						'recursoAcao': 'Leitura',
						'acao': null,
					}
				]
			}
		] as Array<LayoutMenu>;
	}


	async obterTipoUsuario() {
		return [
			{
				label: await this.translateService
					.get("telaUsuario.adminstrador")
					.toPromise(),
				value: 1
			},
			{
				label: await this.translateService
					.get("telaUsuario.tipoApikey")
					.toPromise(),
				value: 2
			},
			{
				label: await this.translateService
					.get("telaUsuario.manager")
					.toPromise(),
				value: 3
			},
			{
				label: await this.translateService
					.get("telaUsuario.operador")
					.toPromise(),
				value: 4
			}
		];
	}

	async obterTipoTemplate() {
		return [
			{
				label: await this.translateService
					.get("telaTemplate.posicional")
					.toPromise(),
				value: 1
			},
			{
				label: await this.translateService
					.get("telaTemplate.delimitado")
					.toPromise(),
				value: 2
			}
		];
	}

	async obterTipoAutenticacaoProvedor() {
		return [
			{
				label: await this.translateService
					.get("tipoAutenticacaoProvedor.key")
					.toPromise(),
				value: 1
			},
			{
				label: await this.translateService
					.get("tipoAutenticacaoProvedor.corpoMensagem")
					.toPromise(),
				value: 2
			},
			{
				label: await this.translateService
					.get("tipoAutenticacaoProvedor.basic")
					.toPromise(),
				value: 3
			},
			{
				label: await this.translateService
					.get("tipoAutenticacaoProvedor.oauth")
					.toPromise(),
				value: 4
			},
			{
				label: await this.translateService
					.get("tipoAutenticacaoProvedor.urlToken")
					.toPromise(),
				value: 5
			},
			{
				label: await this.translateService
					.get("tipoAutenticacaoProvedor.allowAnonymous")
					.toPromise(),
				value: 6
			}
		];
	}

	async obterIntegracaoTipoEnvio() {
		return [
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.api")
					.toPromise(),
				value: 1
			},
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.arquivo")
					.toPromise(),
				value: 2
			},
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.bancoDeDados")
					.toPromise(),
				value: 3
			},
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.ftp")
					.toPromise(),
				value: 4
			},
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.rabbitMQ")
					.toPromise(),
				value: 7
			}
		];
	}

	async obterIntegracaoTipoRetorno() {
		return [
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.api")
					.toPromise(),
				value: 1,
				disabled: false
			},
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.arquivo")
					.toPromise(),
				value: 2,
				disabled: false
			},
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.bancoDeDados")
					.toPromise(),
				value: 3,
				disabled: false
			},
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.ftp")
					.toPromise(),
				value: 4,
				disabled: false
			},
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.webHook")
					.toPromise(),
				value: 5,
				disabled: false
			},
			{
				label: await this.translateService
					.get("tipoRetornoProvedor.response")
					.toPromise(),
				value: 6,
				disabled: true
			}
		];
	}

	async obterOperacoesCardEstrategia() {
		return [
			{
				label: await this.translateService.get("listaOperacaoAgregacao.unique").toPromise(),
				value: 0
			},
			{
				label: await this.translateService.get("listaOperacaoAgregacao.average").toPromise(),
				value: 1
			},
			{
				label: await this.translateService.get("listaOperacaoAgregacao.max").toPromise(),
				value: 2
			},
			{
				label: await this.translateService.get("listaOperacaoAgregacao.min").toPromise(),
				value: 3
			},
			{
				label: await this.translateService.get("listaOperacaoAgregacao.sum").toPromise(),
				value: 4
			},
			{
				label: await this.translateService.get("listaOperacaoAgregacao.cardinal").toPromise(),
				value: 5
			}
		];
	}

	async obterMetodosHttp() {
		return [
			{
				label: await this.translateService.get("metodosHttp.get").toPromise(),
				value: "GET"
			},
			{
				label: await this.translateService.get("metodosHttp.post").toPromise(),
				value: "POST"
			},
			{
				label: await this.translateService.get("metodosHttp.put").toPromise(),
				value: "PUT"
			},
			{
				label: await this.translateService.get("metodosHttp.patch").toPromise(),
				value: "PATCH"
			}
		];
	}

	obterOperacoesOrdenacao() {
		var itens = Object.keys(OrderOperation)
			.filter(status => isNaN(<any>status))
			.map(name =>
				this.translateService
					.get(`ordenacao.${name}`)
					.toPromise()
					.then(label => ({ value: OrderOperation[name], label }))
			);
		itens.push(
			this.translateService
				.get(`ordenacao.equalNumber`)
				.toPromise()
				.then(label => ({ value: OrderOperation.equal, label, numeric: true }))
		);

		return Promise.all(itens);
	}

	async obterListaStatus() {
		const itens = Object.keys(ListaStatus)
			.filter(status => !isNaN(<any>status))
			.filter(
				status =>
					<any>status != ListaStatus.AgMediacao &&
					<any>status != ListaStatus.AgIndexacao
			)
			.map(value =>
				this.translateService
					.get(`statusLista.${ListaStatus[value]}`)
					.toPromise()
					.then(label => ({ value, label }))
			);

		return Promise.all(itens);
	}
}
