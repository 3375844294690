import { TipoCategoria, Template } from './../../common/model/template';
import swal from "sweetalert2";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatTabChangeEvent } from "@angular/material";
import { Subject, Operator, Subscription, forkJoin, Observable } from "rxjs";

import { Carteira } from "../../common/model/carteira";
import { CarteiraUsuario } from "../../common/model/carteira-usuario";
import { Usuario } from "../../common/model/usuario";
import { TranslateService } from "@ngx-translate/core";
import { CarteiraService } from "../../common/service/carteira.service";
import { UsuarioService } from "../../common/service/usuario.service";
import { TemplateService } from "../../common/service/template.service";

@Component({
	selector: "app-form-carteira",
	templateUrl: "./form-carteira.component.html",
	styleUrls: ["./form-carteira.component.scss"],
	providers: [
		{ useClass: CarteiraService, provide: CarteiraService },
		{ useClass: UsuarioService, provide: UsuarioService },
        { useClass: TemplateService, provide: TemplateService }
	]
})
export class FormCarteiraComponent {
	carteira: Carteira;
    carteiraPadrao: Carteira;
    padrao: boolean;
	submited: boolean;
	isEdit: boolean = false;
	usuarios: Usuario[] = [];
	usuariosSource: CarteiraUsuario[] = [];
	usuariosTarget: CarteiraUsuario[] = [];
    templates: Template[] = [];
    templatesSource: Template[] = [];
    templatesTarget: Template[] = [];

    tabSelected: number = 0;

	public get error() {
		if (this.submited) return this.carteira.validar();
	}

	public liveError(property: string) {
		let validationResult = this.carteira.validar();
		if (!validationResult) return null;
		return validationResult[property] ? validationResult[property][0] : null;
	}

	constructor(
		public dialog: MatDialog,
		private carteiraService: CarteiraService,
		private usuarioService: UsuarioService,
        private templateService: TemplateService,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService
	) {
		this.carteira = new Carteira();

        this.carteiraService.obterCarteiraPadrao().subscribe(carteira => {
            this.carteiraPadrao = carteira;
        });

		this.route.params.subscribe(params => {
			if (!params.id) {
				this.usuarioService.obterUsuarios().subscribe(usuarios => {
					this.usuarios = usuarios;
					this.usuariosSource = this.usuarios.map(m => CarteiraUsuario.fromRaw({
						'carteiraUsuarioId': 0,
						'carteiraId': 0,
						'usuarioId': m.usuarioId,
						'dataCadastro': null,
						'ativo': false,
						'nome': m.nome
					}));
				});

                this.templateService.obterTemplates().subscribe(templates => {
                    this.templates = templates;
                    this.templatesSource = templates;
                })
			} else {
				this.isEdit = true;

				let p1 = this.carteiraService.obterPorId(params.id);
				let p2 = this.usuarioService.obterUsuarios();
                let p3 = this.templateService.obterTemplates();

				forkJoin([p1, p2, p3]).subscribe((results: any) => {
					this.carteira = results[0];
					this.usuarios = results[1];
                    this.templates = results[2];

					let carteiraUsuario = this.carteira.carteiraUsuario.filter(f => f.ativo);

					this.usuariosSource = this.usuarios.filter(f => carteiraUsuario.some(s => s.usuarioId == f.usuarioId) == false)
						.map(m => {

							let retorno = CarteiraUsuario.fromRaw({
								'carteiraUsuarioId': 0,
								'carteiraId': this.carteira.carteiraId,
								'usuarioId': m.usuarioId,
								'dataCadastro': null,
								'ativo': false,
								'nome': m.nome
							});

							let desabilitado = this.carteira.carteiraUsuario.find(i => i.usuarioId == m.usuarioId);

							if (desabilitado) {
								retorno.carteiraUsuarioId = desabilitado.carteiraUsuarioId;
								retorno.carteiraId = this.carteira.carteiraId;
								retorno.dataCadastro = desabilitado.dataCadastro;
							}

							return retorno;

						});

					this.usuariosTarget = carteiraUsuario;

                    this.templatesSource = this.templates.filter(f => f.carteiraId != params.id && f.listaTemplateCategoriaId != TipoCategoria.exportacao);
                    this.templatesTarget = this.templates.filter(f => f.carteiraId == params.id && f.listaTemplateCategoriaId != TipoCategoria.exportacao);

                    this.carteiraPadrao.carteiraId == this.carteira.carteiraId ? this.padrao = true : this.padrao = false;
				});
			}
		});
	}

    carteiraPadraoChanged() {
        this.carteira.padrao ? this.carteiraPadrao.padrao = false : this.carteiraPadrao.padrao = true; 
    }

	onMoveToTarget(e: any, TEMPLATE: boolean) {
        TEMPLATE ? e.items[0].updateCarteira = true : e.items[0].ativo = true;
	}

	onMoveToSource(e: any, TEMPLATE: boolean) {
		TEMPLATE ? e.items[0].updateCarteira = false : e.items[0].ativo = false;
	}

	onMoveAllToTarget(e: any, TEMPLATE: boolean) {
        TEMPLATE ? 
        e.items = e.items.map(m => {
			m.updateCarteira = true;
			return m;
		}) 
        :
		e.items = e.items.map((m: CarteiraUsuario) => {
			m.ativo = true;
			return m;
		});
	}

	onMoveAllToSource(e: any, TEMPLATE: boolean) {
        TEMPLATE ? 
        e.items = e.items.map(m => {
			m.updateCarteira = false;
			return m;
		}) 
        :
		e.items = e.items.map((m: CarteiraUsuario) => {
			m.ativo = false;
			return m;
		});
	}

	salvar() {
		this.submited = true;

		this.translate
			.get([
				"telaCarteira.carteira",
				"telaPadrao.camposInvalidos",
				"telaPadrao.sucessoSalvar",
				"telaPadrao.erroSalvar"
			])
			.subscribe((res: Object) => {

				let carteiraUsuario: CarteiraUsuario[] = [];
                let templateCarteira: Array<any> = [];

                this.templatesTarget.forEach(f => {
                    templateCarteira.push({
                        'listaTemplateId': Number(f.listaTemplateId),
                        'updateCarteira': true
                    });
                });

                this.templatesSource.forEach(f => {
                    templateCarteira.push({
                        'listaTemplateId': Number(f.listaTemplateId),
                        'updateCarteira': false
                    });
                });

				this.usuariosTarget
					.forEach((f: CarteiraUsuario) => carteiraUsuario.push(f));

				this.usuariosSource
					.filter((f: CarteiraUsuario) => f.carteiraUsuarioId > 0)
					.forEach((f: CarteiraUsuario) => carteiraUsuario.push(f));

				this.carteira.carteiraUsuario = carteiraUsuario;
                this.carteira.template = templateCarteira;

				if (this.carteira.validar()) {
					swal.fire(res["telaCarteira.carteira"], res["telaPadrao.camposInvalidos"], "error");
					return;
				}

				const sucesso = () => {
					swal.fire(res["telaCarteira.carteira"], res["telaPadrao.sucessoSalvar"], "success");
					this.router.navigate(["/app/carteira"]);
				};

				const erro = () => {
					swal.fire(res["telaCarteira.carteira"], res["telaPadrao.erroSalvar"], "error");
				}

				if (!this.carteira.carteiraId)
					return this.carteiraService.criar(this.carteira).subscribe(sucesso, erro);

				this.carteiraService.atualizar(this.carteira).subscribe(sucesso, erro);
			});
	}

    //#region [ Tab Actions ]

    disabledTab(index: number) {
		if (this.isEdit)
			return false;

		return !(this.tabSelected >= index);
	}

	tabChange(e: MatTabChangeEvent) {
		this.tabSelected = e.index;
	}

	next() {
		if (this.tabSelected == 0)
			this.tabSelected++;
	}

	previous() {
		if (this.tabSelected == 1)
			this.tabSelected--;
	}

    //#endregion
}