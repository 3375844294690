import { Component, OnInit } from "@angular/core";
import { CamposListas, CampoTemplateAgrupadoNomeTipo, FiltroPesquisaLista, ListaCampo, PesquisaLista } from "../../common/model/pesquisa-lista";
import { TemplateService } from "../../common/service/template.service";
import { ElasticsearchRepositoryService } from "../../elasticsearch/repository/elasticsearch-repository.service";
import { MatDialog } from "@angular/material";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { montarQueryPesquisaCampo } from "../utils/montarQueryPesquisaCampo";

import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";
import { Template } from "../../common/model/template";
import { isNull, isObjectEmpty } from "../../common/utils";

@Component({
    selector: "app-pesquisa-lista",
    templateUrl: "./pesquisa-lista.component.html",
    styleUrls: ["./pesquisa-lista.component.scss"],
    providers: [
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService }
    ]
})

export class PesquisaListaComponent implements OnInit {
    public filtro: FiltroPesquisaLista;
    public listaCampos: Array<CampoTemplateAgrupadoNomeTipo> = [];
    public listasEncontradas: Array<PesquisaLista> = [];


    carteiras: Array<Carteira> = [];
    templates: Array<Template> = [];

    constructor(
        private carteiraService: CarteiraService,
        private templateService: TemplateService,
        private searchService: ElasticsearchRepositoryService,
        private dialog: MatDialog
    ) {
        this.filtro = new FiltroPesquisaLista();

        this.obterCarteiras();
    }

    ngOnInit() {
        // this.limpar();
        // this.obterCamposMapeados();
    }

    //#region [ Eventos ]

    carteiraChange() {
        this.filtro.template = null;
        this.filtro.nomeCampo = null;
        this.obterTemplates();
        this.obterCamposMapeados();
    }

    templateChange() {
        this.filtro.nomeCampo = null;
        this.obterCamposMapeados();
    }

    //#endregion

    //#region [ Consultar dados basicos ]

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
                this.carteiras = carteiras;

                let padrao = this.carteiras.find(f => f.padrao);
                this.filtro.carteiraId = padrao.carteiraId;

                this.obterTemplates();
                this.obterCamposMapeados();
            });
    }

    obterTemplates() {
        this.templateService
            .obterTemplates({ 'carteiraId': this.filtro.carteiraId, 'categoria': 1 })
            .subscribe(templates => (this.templates = templates));
    }



    obterCamposMapeados() {
        this.templateService.obterCamposAgrupadoNomeTipo({ 'carteiraId': this.filtro.carteiraId, 'templateId': this.filtro.template }).subscribe(result => {
            this.listaCampos = result;
        });
    }

    //#endregion

    onCampoSelecionado(campoAgrupado: any) {
        if (campoAgrupado && campoAgrupado.type == 'change') return;
        this.filtro.campoAgrupado = campoAgrupado;
    }

    pesquisar() {
        this.listasEncontradas = [];
        this.filtro.campoAgrupado.camposListas.forEach((campo: CamposListas) => {
            campo.listas.forEach((lista: ListaCampo) => {
                let jsonQuery = montarQueryPesquisaCampo(campo.nomeCampo, this.filtro.valorCampo, this.filtro.campoAgrupado.listaCampoTipoId);
                this.searchService.getQuantidadeFiltros(lista.nomeLista, jsonQuery).subscribe(quantidade => {
                    if (quantidade[0] > 0) {
                        this.listasEncontradas.push(new PesquisaLista(lista.listaId, lista.nomeLista, lista.listaTemplateId, quantidade[0], jsonQuery));
                    }
                });
            });
        })
    }

    desabilitarBotaoPesquisar() {
        return this.filtro.nomeCampo == null || this.filtro.valorCampo == null || this.filtro.valorCampo == ''
    }

    visualizarRegistros(lista: PesquisaLista) {
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: lista.listaTemplateId,
                lista: lista.nomeLista,
                listaId: lista.listaId,
                query: lista.query
            }
        });
    }

    limpar() {
        this.filtro = new FiltroPesquisaLista();
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro.carteiraId = padrao.carteiraId;
    }

    customSearchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.value.toString().toLocaleLowerCase().indexOf(term) > -1 || item.label.toString().toLocaleLowerCase().indexOf(term) > -1;
    }
}