import { Component, ViewChild, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DataView } from 'primeng/dataview';

import { Fluxo, FluxoTipo } from "../../common/model/fluxo";
import { Lista } from "../../common/model/lista";
import { FiltroLista } from '../../common/model/filtro-lista';

import { FluxoService } from "../../common/service/fluxo.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { ListaService } from "../../common/service/lista.service";

import { CarteiraService } from "../../common/service/carteira.service";
import { Carteira } from "../../common/model/carteira";


@Component({
	selector: "app-lista-fluxo-estrategia",
	templateUrl: "./lista-fluxoestrategia.component.html",
	styleUrls: ["./lista-fluxoestrategia.component.scss"],
	providers: [
		{ useClass: CarteiraService, provide: CarteiraService },
		{ useClass: FluxoService, provide: FluxoService },
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class ListaFluxoEstrategiaComponent implements OnInit {
	fluxos: Array<Fluxo> = [];
	carteiras: Array<Carteira> = [];
	listas: Array<Lista>;
	tipos: Array<any>;
	filtro = { 'carteiraId': null, 'lista': '', 'nome': '', 'fluxoTipoId': '' };

	public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1);
	}

	@ViewChild(DataView, { static: false }) dataTable: DataView;
	constructor(
		private carteiraService: CarteiraService,
		private fluxoService: FluxoService,
		private listaService: ListaService,
		private route: ActivatedRoute,
		private customAlertService: CustomAlertService,
		public dialog: MatDialog
	) { }

	ngOnInit() {
		this.obterCarteiras();
		this.obterListas();
		this.obterTipos();
		this.filtrarFluxos();
	}

	filtrarFluxos() {
		this.fluxoService.obterFluxos(this.filtro).subscribe(data => {
			this.fluxos = data;
			this.setCurrentPage(0);
		});
	}

	carteiraChange() {
		this.obterListas();
	}

	obterCarteiras() {
		this.carteiraService.obterCarteiras().subscribe(carteiras => {
				this.carteiras = carteiras;

				let padrao = this.carteiras.find(f => f.padrao);
				this.filtro.carteiraId = padrao.carteiraId;

				this.obterListas();
			});
	}

	obterListas() {
		let filtroLista = new FiltroLista();
		filtroLista.ignorarErroPesquisa = true;
		filtroLista.carteiraId = this.filtro.carteiraId;
		this.listaService.obterListaSemQuantidade(filtroLista).subscribe(listas => (this.listas = listas));
	}

	obterTipos() {
		this.tipos = [
			{ 'fluxoTipoId': FluxoTipo.processo, 'nome': 'Fluxo de Processo' },
			{ 'fluxoTipoId': FluxoTipo.cascata, 'nome': 'Fluxo de Cascata' },
			{ 'fluxoTipoId': FluxoTipo.regua, 'nome': 'Fluxo de Régua' }
		];
	}

	setCurrentPage(n: number) {
		if (this.dataTable)
			this.dataTable.first = n;
	}

	limpar() {
        let padrao = this.carteiras.find(f => f.padrao);
		this.filtro = { 'carteiraId': padrao.carteiraId, 'lista': '', 'nome': '', 'fluxoTipoId': '' };
	}

	excluir(fluxoId: number) {
		this.customAlertService
			.confirmationMessage("telaFluxo.confirmacaoDesativarFluxo")
			.then(() => {
				this.fluxoService
					.desativarFluxo(fluxoId)
					.subscribe((retorno: any) => {
						this.filtrarFluxos();
					});
			});
	}
}