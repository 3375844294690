
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import * as signalR from "@aspnet/signalr";
import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest, HttpHeaders, HttpParams } from "@angular/common/http";
import { HubConnectionBuilder, HubConnection } from "@aspnet/signalr";

import { Lista } from "../model/lista";
import { FiltroLista } from "../model/filtro-lista";
import { environment } from "../../../../environments/environment";
import { convertToQueryString } from "./query-string";
import { ListaCard } from "../../dash/model/lista-card";
import { FiltroDashPrincipal } from "../../dash/model/filtro-dash-principal";
import { EstrategiaRecorrencia } from "../model/estrategia";
import { ImportacaoAutomaticaConfiguracao } from "../model/importacao-automatica";
import { ImportacaoAutomaticaHistorico } from "../model/importacao-automatica-historico" ;
import * as moment from "moment";
import { LimpezaAutomaticaConfiguracao } from '../model/limpeza-automatica-configuracao';
import { isNull, isNullOrZero } from '../utils';
import { LimpezaFiltroArquivo } from '../model/limpeza-filtro-arquivo';
import { Http, ResponseContentType, Headers } from "@angular/http";
import { AutenticacaoService } from "./autenticacao.service";

@Injectable()
export class ListaService {
	static instance: ListaService;

	constructor(private _httpClient: HttpClient, 
		        private http: Http,
				private authService: AutenticacaoService) {
		this.conectarSignalR();
		ListaService.instance = this;
	}

	obterQuantidadeLista(listaId, estrategiaId): Observable<any> {
		return this._httpClient.get<any>(
			`${environment.serverUrl
			}/api/listas/${listaId}/quantidades?estrategiaId=${estrategiaId}`
		);
	}
	obterListaComTemplate(filter): any {
		var query = convertToQueryString(filter);
		return this._httpClient.get<Lista>(
			`${environment.serverUrl}/api/listas/template${query}`
		);
	}
	private _hubConnection: HubConnection | undefined;
	public signalRStatus: boolean = false;

	public conectarSignalR() {
		this._hubConnection = new HubConnectionBuilder()
			.withUrl(`${environment.serverUrl}/notificacao`)
			.configureLogging(signalR.LogLevel.Information)
			.build();
	}

	public reconectarSignalR(listas: Array<Lista>) {
		this._hubConnection.onclose(() => {
			this.signalRStatus = false;
			this.conectarSignalR();
			this.escutarProgressoListas(listas);
			this.reconectarSignalR(listas);
		});
	}

	public escutarProgressoListas(listas: Array<Lista>) {
		this._hubConnection.start().then(() => {
			this.signalRStatus = true;
			this._hubConnection.on("ProgressoIndexacao", conteudo => {

				let lista = listas.find(l => l.listaId == conteudo.lista);

				if (!lista) return;

				if (conteudo.porcentagem)
					lista.porcentagemIndexacao = parseFloat(conteudo.porcentagem);

				if (conteudo.status)
					lista.listaStatusId = parseInt(conteudo.status);

				if (conteudo.processando !== undefined && conteudo.processando !== null && conteudo.processando !== '')
					lista.processando = conteudo.processando;

				if (conteudo.quantidadeLivre)
					lista["quantidadeLivre"] = parseInt(conteudo.quantidadeLivre);

				if (conteudo.quantidadeDistribuida)
					lista["quantidadeDistribuida"] = parseInt(conteudo.quantidadeDistribuida);

				if (conteudo.totalGeral)
					lista["totalGeral"] = parseInt(conteudo.totalGeral);

				if (conteudo.execucaoInicio)
					lista["execucaoInicio"] = conteudo.execucaoInicio;

				if (conteudo.execucaoAtual) {
					let ea = moment(conteudo.execucaoAtual, "DD/MM/YYYY HH:mm:ss");
					let eaf = moment();
					let diferenca = (ea.isBefore(eaf)) ? eaf.diff(ea, 'seconds') : (0 - ea.diff(eaf, 'seconds'));
					lista["execucaoAtual"] = conteudo.execucaoAtual;
					lista["execucaoAtualFront"] = moment().format("DD/MM/YYYY HH:mm:ss");
					lista["execucaoAtualDiferenca"] = diferenca;
				}

				if (conteudo.tempoPagina)
					lista["tempoPagina"] = conteudo.tempoPagina;

				if (conteudo.paginasRestantes)
					lista["paginasRestantes"] = conteudo.paginasRestantes;

				if (conteudo.tamanho)
					lista["tamanho"] = conteudo.tamanho;

				if (conteudo.totalIndexado)
					lista["totalIndexado"] = conteudo.totalIndexado;

				if (conteudo.totalRestante)
					lista["totalRestante"] = conteudo.totalRestante;

				localStorage.setItem(`listaIndexacao_${lista.listaId}`, JSON.stringify(lista));
			});
		});
	}

	public paraEscutarProgressoLista() {
		this._hubConnection.stop();
	}

	public obterLista(filtroLista?: FiltroLista): Observable<Array<Lista>> {
		let url = `${environment.serverUrl}/api/listas`;
		if (filtroLista)
			url +=
				"?" +
				Object.keys(filtroLista)
					.map(prop => `${prop}=${filtroLista[prop]}`)
					.join("&");
		return this._httpClient
			.get<Array<Lista>>(url).pipe(
				map(lista => lista.map(Lista.fromRaw)));
	}

	public obterListaSemQuantidade(filtroLista?: FiltroLista): Observable<Array<Lista>> {
		let url = `${environment.serverUrl}/api/listas/obter-sem-quantidade`;
		if (filtroLista)
			url +=
				"?" +
				Object.keys(filtroLista)
					.map(prop => `${prop}=${filtroLista[prop]}`)
					.join("&");
		return this._httpClient
			.get<Array<Lista>>(url).pipe(
				map(lista => lista.map(Lista.fromRaw)));
	}

    public obterListaIgnorandoPermissao(filtroLista?: FiltroLista): Observable<Array<Lista>> {
		let url = `${environment.serverUrl}/api/listas/obter-ignorando-permissao`;
		if (filtroLista)
			url +=
				"?" +
				Object.keys(filtroLista)
					.map(prop => `${prop}=${filtroLista[prop]}`)
					.join("&");
		return this._httpClient
			.get<Array<Lista>>(url).pipe(
				map(lista => lista.map(Lista.fromRaw)));
	}

	public obterListaEmIndexacao(listaId?: number): Observable<Array<any>> {
		let url = `${environment.serverUrl}/api/listas/listas-em-indexacao`;
		
		if (listaId)
			url += `?id=${listaId}`;

		return this._httpClient.get<Array<any>>(url)
	}


	public obterHistoricoIndexacao(listId, dataInicio, dataFim): Observable<any> {
		let url = `${environment.serverUrl}/api/listas/historicoIndexacao/${listId}`;
		url += "?dataInicio=" + dataInicio + "&dataFim=" + dataFim;
		return this._httpClient.get<any>(url)
	}

	public obterHistoricoIndexacaoTodasListas(listId, dataInicio, dataFim, carteiraId): Observable<Array<any>> {
		let url = `${environment.serverUrl}/api/listas/historicoIndexacaotodaslistas?dataInicio=${dataInicio}&dataFim=${dataFim}`;

		if (listId != null || listId != undefined)
			url += "&id=" + listId;

        if (carteiraId != null || carteiraId != undefined)
            url += "&carteiraId=" + carteiraId;
        
		return this._httpClient.get<any>(url)
	}

	public obterEstrategiaRecorrencia(): Array<any> {

		let recorrencias = new Array<any>(
			{ 'id': EstrategiaRecorrencia.agendada, 'descricao': 'Agendada' },
			{ 'id': EstrategiaRecorrencia.imediata, 'descricao': 'Imediata' },
			{ 'id': EstrategiaRecorrencia.recorrente, 'descricao': 'Recorrente' }
		);

		return recorrencias;
	}

	public obterListaPorId(id) {
		return this._httpClient
			.get<Lista>(`${environment.serverUrl}/api/listas/${id}`).pipe(
				map(Lista.fromRaw));
	}

	private prepararFormData(lista: Lista, arquivos: Array<any>) {
		const formData = new FormData();
		formData.append("lista", JSON.stringify(lista));
		this.adicionarArquivosFormData(formData, arquivos);
		return formData;
	}

	private adicionarArquivosFormData(formData: FormData, arquivos: Array<any>) {
		arquivos.forEach(arquivo =>
			formData.append(arquivo.descricao, arquivo.file, arquivo.file.name)
		);
	}

	public criarLista(lista: Lista) {
		return this._httpClient.post<number>(
			`${environment.serverUrl}/api/listas/`,
			lista
		);
	}

	public atualizarLista(lista: Lista) {
		return this._httpClient.put<number>(
			`${environment.serverUrl}/api/listas/${lista.listaId}`,
			lista
		);
	}

	public ativarLista(id) {
		return this._httpClient.patch<Lista>(
			`${environment.serverUrl}/api/listas/${id}/indexar`,
			null
		);
	}

	public atualizarCardCustomizado(dados: any) {
		return this._httpClient.patch<Lista>(
			`${environment.serverUrl}/api/listas/${dados.id}/card-customizado`,
			dados
		);
	}

	public atualizarCardSomaUnicos(dados: any) {
		return this._httpClient.patch<Lista>(
			`${environment.serverUrl}/api/listas/${dados.id}/card-soma-unicos`,
			dados
		);
	}

	public pararLista(id) {
		return this._httpClient.patch<Lista>(
			`${environment.serverUrl}/api/listas/${id}/parar`,
			null
		);
	}

	public pausarLista(id) {
		return this._httpClient.patch<Lista>(
			`${environment.serverUrl}/api/listas/${id}/pausar`,
			null
		);
	}

	public cancelarLista(id) {
		return this._httpClient.patch<Lista>(
			`${environment.serverUrl}/api/listas/${id}/cancelar`,
			null
		);
	}
	
	public limparLista(listaId: number): any {
		return this._httpClient.patch<Lista>(
			`${environment.serverUrl}/api/listas/${listaId}/limpar`,
			null
		);
	}

	// public zerarLista(listaId: number): any {
	// 	return this._httpClient.patch<Lista>(
	// 		`${environment.serverUrl}/api/listas/${listaId}/zerar`,
	// 		null
	// 	);
	// }

	public obterArquivosLista(listaId) {
		return this._httpClient.get<Array<any>>(
			`${environment.serverUrl}/api/listas/${listaId}/arquivos`
		);
	}

	public obterListaCard(listId, carteiraId) {
		return this._httpClient.get<ListaCard>(
			`${environment.serverUrl}/api/dashboard/card-lista?listaId=${listId}&carteiraId=${carteiraId}`
		);
	}

	limparDistribuidosLista(listaId: number): any {
		return this._httpClient.patch<Lista>(
			`${environment.serverUrl}/api/listas/${listaId}/limpar-distribuidos`,
			null
		);
	}

	public obterListaGrafico(filtroDashPrincipal: FiltroDashPrincipal) {
		return this._httpClient.get<object>(
			`${environment.serverUrl}/api/dashboard/grafico${convertToQueryString(
				filtroDashPrincipal
			)}`
		);
	}

	public criarArquivosLista(listaId, listaArquivo: Array<any>) {
		let formData: FormData = new FormData();
		listaArquivo.forEach(arquivo =>
			formData.append(arquivo.name, arquivo, arquivo.name)
		);

		let params = new HttpParams();
		const options = {
			params: params,
			reportProgress: true
		};

		const req = new HttpRequest(
			'POST',
			`${environment.serverUrl}/api/listas/${listaId}/arquivos`,
			formData,
			options
		);
		return this._httpClient.request(req);
	}

	public getArquivoListaUrl = (listaId) => `${environment.serverUrl}/api/listas/${listaId}/arquivos`;



	obterPreviewArquivoImportacao(listaId, arquivoId: number, consistente: boolean, pagina) {
		return this._httpClient.get<any>(
			`${environment.serverUrl
			}/api/listas/${listaId}/arquivos/${arquivoId}/preview?consistente=${consistente}&pagina=${pagina}`
		);
	}

	obterPreviewHistoricoErro(historicoId, pagina) {
		return this._httpClient.get<any>(
			`${environment.serverUrl
			}/api/listas/historicoIndexacao/${historicoId}/previewerro?pagina=${pagina}`
		);
	}



	confirmarArquivos(listaId, listaArquivoSelecionado: Array<number>) {
		return this._httpClient.patch<any>(`${environment.serverUrl}/api/listas/${listaId}/arquivos/statusProcessamento?idArquivos=${listaArquivoSelecionado.join(",")}`, null);
	}

	obterCamposMapeados(id) {
		return this._httpClient.get<Array<any>>(
			`${environment.serverUrl}/api/listas/${id}/campos-mapeados`
		);
	}

	excluirArquivos(listaId, listaArquivoSelecionado: Array<number>) {
		const req = new HttpRequest(
			"DELETE",
			`${environment.serverUrl
			}/api/listas/${listaId}/arquivos?idArquivos=${listaArquivoSelecionado.join(
				","
			)}`
		);
		return this._httpClient.request(req);
	}

	incluirItemLista(listaId, json) {
		return this._httpClient.post<any>(
			`${environment.serverUrl}/api/listas/${listaId}/itens`,
			json
		);
	}

	alterarItemLista(listaId, idItem, json) {
		return this._httpClient
			.put<number>(
				`${environment.serverUrl}/api/listas/${listaId}/${idItem}/itens`,
				json
			).pipe(
				map(m => m));
	}

	excluirItemLista(lista, itemId) {
		return this._httpClient.delete(
			`${environment.serverUrl}/api/listas/${lista}/${itemId}/itens`
		);
	}

	public getImportacaoAutomaticaPorId(id: number): Observable<ImportacaoAutomaticaConfiguracao> {
		let url = `${environment.serverUrl}/api/listas/importacaoautomaticaporid/${id}`;
		return this._httpClient.get<ImportacaoAutomaticaConfiguracao>(url).pipe(map(ImportacaoAutomaticaConfiguracao.fromRaw));
	}

	public getImportacaoAutomaticaHistorico(id: number, blockLoading:boolean): Observable<Array<ImportacaoAutomaticaHistorico>> {
		if (blockLoading) {
			let headers = new HttpHeaders().set('blockLoading', 'true');
			return this._httpClient.get<Array<ImportacaoAutomaticaHistorico>>(`${environment.serverUrl}/api/listas/importacaoautomaticahistorico/${id}`, { headers: headers });//.pipe(map(ImportacaoAutomaticaHistorico.fromRaw));
		}
		else {
			return this._httpClient.get<Array<ImportacaoAutomaticaHistorico>>(`${environment.serverUrl}/api/listas/importacaoautomaticahistorico/${id}`);//.pipe(map(ImportacaoAutomaticaHistorico.fromRaw));
		}
	}

	public getImportacaoAutomaticaPorListaId(listaId: number): Observable<ImportacaoAutomaticaConfiguracao> {
		let url = `${environment.serverUrl}/api/listas/importacaoautomaticaporlista/${listaId}`;
		return this._httpClient.get<ImportacaoAutomaticaConfiguracao>(url).pipe(map(ImportacaoAutomaticaConfiguracao.fromRaw));
	}

	public setStatusImportacaoAutomatica(id: number, listaId: number, status: boolean): Observable<ImportacaoAutomaticaConfiguracao> {
		return this._httpClient.post<ImportacaoAutomaticaConfiguracao>(
			`${environment.serverUrl}/api/listas/importacaoautomatica/${id}/status`,
			{ listaId: listaId, ativo: status }
		).pipe(map(ImportacaoAutomaticaConfiguracao.fromRaw));
	}

	atualizarImportacaoAutomatica(importacaoAutomatica: ImportacaoAutomaticaConfiguracao) {
		return this._httpClient.put<ImportacaoAutomaticaConfiguracao>(
			`${environment.serverUrl}/api/listas/importacaoautomatica/${importacaoAutomatica.importacaoAutomaticaConfiguracaoId}`,
			importacaoAutomatica
		);
	}

	validarDiretorio(diretorio: any) {
		return this._httpClient.post(
			`${environment.serverUrl}/api/listas/validar-diretorio`,
			diretorio
		);
	}


	public obterLimpezaAutomaticaPorListaId(listaId: number): Observable<LimpezaAutomaticaConfiguracao> {
		let url = `${environment.serverUrl}/api/listas/limpezaautomaticaporlista/${listaId}`;
		return this._httpClient.get<LimpezaAutomaticaConfiguracao>(url).pipe(map(LimpezaAutomaticaConfiguracao.fromRaw));
	}

	public setStatusLimpezaAutomatica(id: number, listaId: number, status: boolean): Observable<LimpezaAutomaticaConfiguracao> {
		return this._httpClient.post<LimpezaAutomaticaConfiguracao>(
			`${environment.serverUrl}/api/listas/limpezaautomatica/${id}/status`,
			{ listaId: listaId, ativo: status }
		).pipe(map(LimpezaAutomaticaConfiguracao.fromRaw));
	}

	public gravarLimpezaAutomatica(model: LimpezaAutomaticaConfiguracao) {
		if (isNullOrZero(model.limpezaAutomaticaConfiguracaoId)) {
			return this._httpClient.post<number>(`${environment.serverUrl}/api/listas/limpezaautomatica/`, model);
		} else {
			return this._httpClient.put<number>(`${environment.serverUrl}/api/listas/limpezaautomatica/${model.limpezaAutomaticaConfiguracaoId}`, model);
		}
	}


	public getUrlLimpezaFiltroArquivoUpload = (listaId) => `${environment.serverUrl}/api/listas/${listaId}/upload-limpeza-filtro-arquivo`;

	public obterLimpezaFiltroArquivoPorListaId(listaId: number): Observable<Array<LimpezaFiltroArquivo>> {
		return this._httpClient.get<Array<LimpezaFiltroArquivo>>(`${environment.serverUrl}/api/listas/${listaId}/obter-limpezas-filtro-arquivo-lista/`)
			.pipe(map(LimpezaFiltroArquivo.fromRawArray));
		//return this._httpClient.get<LimpezaFiltroArquivo>(url).pipe(map(LimpezaFiltroArquivo.fromRaw));
	}

	public obterListasAguardandoIndexacao(blockLoading: boolean = false): Observable<any> {

		console.log(blockLoading);

		if (blockLoading) {
			let headers = new HttpHeaders().set('blockLoading', 'true');
			return this._httpClient.get<any>(`${environment.serverUrl}/api/listas/listas-aguardando-indexacao`, { headers: headers });
		}
		else {
			return this._httpClient.get<any>(`${environment.serverUrl}/api/listas/listas-aguardando-indexacao`);
		}


	}

	public baixarArquivoErro(idArquivo: number ) {
		return this.http
		.get(`${environment.serverUrl}/api/listas/baixarArquivoErro/${idArquivo}`,
			{ responseType: ResponseContentType.Blob,
				headers: new Headers({
					Authorization: `Bearer ${this.authService.obterTokenAutenticado()}`
				})
			}
		);
	}

}