import { Component, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CustomAlertService } from "../../../common/service/custom-alert.service";
//import { FormatacaoCampo } from "../../../common/model/formatacao-campo";
import { TranslateService } from "@ngx-translate/core";
import { FormTemplateImportacaoComponent } from "../../form-template-importacao/form-template-importacao.component";
import { ListaTemplateItem } from "../../../common/model/lista-template-item";
import { isNull, isNullOrEmpty, isNullOrZero } from "../../../common/utils";
import { CampoTipo } from "../../../common/model/campo-tipo";
import { CampoIntegracao } from "../../../common/model/campo-integracao";
import { ListaTipoCampoFormatacao, ListaTipoCampoFormatacaoLocal } from "../../../common/model/lista-tipo-campo-formatacao";

@Component({
    selector: "app-campo-importacao-modal",
    templateUrl: "./campo-importacao-modal.component.html",
    styleUrls: ["./campo-importacao-modal.component.scss", "../../style.css",],
    providers: [{ useClass: CustomAlertService, provide: CustomAlertService }]
})
export class CampoImportacaoModalComponent {

    isInvalid: boolean = false;
    camposIntegracao: Array<any>;
    listaTemplateItem: Array<any>;
    campoSelecionado: ListaTemplateItem;
    campoTemQuebraLinha: any;
    //permiteFormatacao: boolean;
    formatos: Array<ListaTipoCampoFormatacao>;

    templateItemCustomizado: string;

    _formatacaoSelecionada: string;

    public get formatacaoSelecionada(): string {
        return this._formatacaoSelecionada;
    }

    public set formatacaoSelecionada(v: string) {
        this._formatacaoSelecionada = v;
        this.campoSelecionado.conteudo = v;
    }

    //'Texto', 'Decimal', 'Data', 'Lista', 'Boolean', 'Inteiro', 'Long'
    funcoesCollection: Array<any> = [
        { 'id': '4', 'restricao': ['Data'], 'descricao': this.translate.instant("telaTemplate.fncAddDaysTitulo"), 'funcao': 'AddDays(0)', 'tooltip': 'fncAddDays' },
        { 'id': '2', 'restricao': ['Texto', 'Decimal', 'Lista', 'Boolean', 'Inteiro', 'Long'], 'descricao': this.translate.instant("telaTemplate.fncReplaceTitulo"), 'funcao': 'Replace("A","B")', 'tooltip': 'fncReplace' },
        { 'id': '1', 'restricao': ['Texto', 'Decimal', 'Lista', 'Boolean', 'Inteiro', 'Long'], 'descricao': this.translate.instant("telaTemplate.fncSubStringTitulo"), 'funcao': 'Substring(0,2)', 'tooltip': 'fncSubString' },
        { 'id': '3', 'restricao': ['Texto', 'Decimal', 'Lista', 'Boolean', 'Inteiro', 'Long'], 'descricao': this.translate.instant("telaTemplate.fncTrimTitulo"), 'funcao': 'Trim()', 'tooltip': 'fncTrim' },
        { 'id': '5', 'restricao': ['Texto', 'Decimal', 'Lista', 'Boolean', 'Inteiro', 'Long'], 'descricao': this.translate.instant("telaTemplate.fncSubStringTerceiroTitulo"), 'funcao': 'Substring(2)', 'tooltip': 'fncSubStringTerceiro' }
    ];

    public get permiteFormatacao(): boolean {
        if (isNull(this.campoSelecionado) || isNullOrZero(this.campoSelecionado.listaCampoTipoId))
            return false;

        let campoTipo: CampoIntegracao = this.data.camposIntegracao.find((f: CampoIntegracao) => f.listaCampoTipoId.toString() == this.campoSelecionado.listaCampoTipoId.toString());

        return (campoTipo.listaCampoTipoFormatacao.filter((f: ListaTipoCampoFormatacao) => (f.local == ListaTipoCampoFormatacaoLocal.Ambos || f.local == ListaTipoCampoFormatacaoLocal.Importacao)).length > 0)
    }

    public get validaAdicionarCampoConteudo(): boolean {
        return (!isNullOrEmpty(this.campoSelecionado.nome) && !isNullOrEmpty(this.campoSelecionado.listaCampoTipoId));
    }

    public get fncCollection() {
        if (isNullOrEmpty(this.campoSelecionado.listaCampoTipoId))
            return [];

        let campoTipo: CampoIntegracao = this.data.camposIntegracao.find((f: CampoIntegracao) => f.listaCampoTipoId.toString() == this.campoSelecionado.listaCampoTipoId.toString());

        return this.funcoesCollection.filter((f: any) => (f.restricao as Array<any>).some(s => s == campoTipo.nome));
    }

    @ViewChild("conteudo", { static: false }) conteudoRef: ElementRef;
    constructor(
        private ref: MatDialogRef<FormTemplateImportacaoComponent>,
        private customAlertService: CustomAlertService,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: {
            camposIntegracao: CampoIntegracao[],
            listaTemplateItem: ListaTemplateItem[],
            campoSelecionado: ListaTemplateItem,
            exibirQuantidade: boolean
        }
    ) {

        if (this.data.camposIntegracao)
            this.camposIntegracao = data.camposIntegracao.map((c: CampoIntegracao) => ({ value: c.listaCampoTipoId.toString(), label: c.nome }));

        if (data.listaTemplateItem)
            this.listaTemplateItem = data.listaTemplateItem;

        if (this.data.campoSelecionado) {
            this.campoSelecionado = ListaTemplateItem.fromRaw(data.campoSelecionado);
            this.campoPermiteFormatacao();
        } else {
            this.campoSelecionado = new ListaTemplateItem(null, null, null, null);
            this.campoSelecionado.tipoCampoImportacao = 0;
            //this.permiteFormatacao = false;
        }

        this.campoTemQuebraLinha = isNullOrEmpty(this.campoSelecionado.guidQuebraColuna) == false;
    }

    tipoCampoImportacaoChanged() {
        this.formatacaoSelecionada = '';
        this.campoSelecionado.conteudo = '';
    }

    listaCampoTipoChanged() {
        if (this.campoSelecionado.listaCampoTipoId) {
            this.campoPermiteFormatacao()
        }
        else {
            this.campoSelecionado.conteudo = '';
        }
    }

    onNomeChange() {
        if(this.campoSelecionado.nome.startsWith('_'))
            this.campoSelecionado.nome = this.campoSelecionado.nome.replace(/^_/, '');;
    }

    campoPermiteFormatacao() {
        let campoTipo: CampoIntegracao = this.data.camposIntegracao.find((f: CampoIntegracao) => f.listaCampoTipoId.toString() == this.campoSelecionado.listaCampoTipoId.toString());

        if (!isNull(campoTipo)) {
            if (this.campoSelecionado.tipoCampoImportacao == 0)
                this.formatacaoSelecionada = this.campoSelecionado.conteudo;

            let a: Array<string> = ['F2', 'F1', 'N2', 'N1', 'C2'];
            let b: Array<string> = ['Telefone', 'Tratar Telefone', 'DDD', 'DDI'];
            let c: any = { 'DDI': '55', 'DDD': '11', 'Telefone': '999999999', 'Tratar Telefone': '11999999999' };

            this.formatos = campoTipo.listaCampoTipoFormatacao.map((m: ListaTipoCampoFormatacao) => {
                if (a.some((s: string) => s == m.formato))
                    m.label = `${m.formato} - Ex: ${m.exemplo}`;
                else if (b.some((s: string) => s == m.formato))
                    m.label = `${m.formato} - Ex: ${c[m.formato]}`;
                else
                    m.label = m.formato;
                return m;
            });

        } else {
            this.formatos = [];
        }
    }

    adicionarCampoConteudo() {
        if (this.validaAdicionarCampoConteudo) {
            if (this.templateItemCustomizado) {
                let fnc = this.funcoesCollection.find((f: any) => (f.id == this.templateItemCustomizado));
                this.campoSelecionado.conteudo = this.campoSelecionado.conteudo + "${" + this.campoSelecionado.nome + "." + fnc.funcao + (this.formatacaoSelecionada ? "|" + this.formatacaoSelecionada : "") + "}";
            } else {
                this.campoSelecionado.conteudo = this.campoSelecionado.conteudo + "${" + this.campoSelecionado.nome + (this.formatacaoSelecionada ? "|" + this.formatacaoSelecionada : "") + "}";
            }

            this.formatacaoSelecionada = '';
            this.templateItemCustomizado = undefined;
            this.conteudoRef.nativeElement.focus();
        }
    }

    strTooltipFuncoes() {
        if (isNullOrZero(this.templateItemCustomizado))
            return "";

        let fnc = this.funcoesCollection.find((f: any) => (f.id == this.templateItemCustomizado));
        return this.translate.instant("telaTemplate." + fnc.tooltip)
    }

    strTooltipExemplo() {
        let formato = this.formatos.find((f: any) => f.formato == this.campoSelecionado.conteudo);
        return (isNull(formato)) ? '' : 'Exemplo: ' + formato.exemplo;
    }

    validarCampo() {
        if (!this.campoSelecionado.nome || this.campoSelecionado.nome.trim().length == 0)
            return true;

        if (this.campoSelecionado.tipoCampoImportacao == 1 && (!this.campoSelecionado.conteudo || this.campoSelecionado.conteudo.trim().length == 0))
            return true;

        if (this.campoSelecionado.tipoCampoImportacao == 0 && !this.campoSelecionado.listaCampoTipoId)
            return true;

        if (this.data.exibirQuantidade && isNull(this.campoSelecionado.tamanho))
            return true;

        if(this.campoSelecionado.nome.startsWith('_'))
            return true;

        if (this.campoSelecionado.nome.toLowerCase() == "id" || this.campoSelecionado.nome.toLowerCase() == "_id" || this.campoSelecionado.nome.toLowerCase() == "id_" || this.campoSelecionado.nome.toLowerCase() == "_id_") {
            this.isInvalid = true;
            return true;
        }

        this.isInvalid = false;
        return false;
    }

    save() {
        let existe = this.listaTemplateItem.some((c: ListaTemplateItem) => c.nome.toLowerCase() == this.campoSelecionado.nome.toLowerCase()
            && c.ativo == true
            && c.ordem != this.campoSelecionado.ordem
            && c.tamanho == this.campoSelecionado.tamanho);

        if (!existe) {
            if (this.campoSelecionado.tipoCampoImportacao == 0 && !isNullOrEmpty(this.formatacaoSelecionada))
                this.campoSelecionado.conteudo = this.formatacaoSelecionada;

            return this.ref.close(this.campoSelecionado);
        }

        this.customAlertService.show("telaPadrao.validacao", "telaTemplate.camposRepetidos", "error");
    }

    close() {
        this.ref.close();
    }


}
