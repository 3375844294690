import { Component, Output, EventEmitter } from "@angular/core";
import { Carteira } from "../../common/model/carteira";
import { CarteiraService } from "../../common/service/carteira.service";

@Component({
  selector: "app-filtro-usuario",
  templateUrl: "./filtro-usuario.component.html",
  styleUrls: ["../style.scss"],
  providers: [{ useClass: CarteiraService, provide: CarteiraService }]
})

export class FiltroUsuarioComponent {
    filtro: any = {};
    carteiras: Array<Carteira> = [];

    @Output()
    filtrar: EventEmitter<any> = new EventEmitter<any>();

    constructor(private carteiraService: CarteiraService) { }

    ngOnInit() {
        this.obterCarteiras();
    }

    public get disabledCarteira(): boolean {
        return (this.carteiras.length == 1);
    }
    
    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras;

            let padrao = this.carteiras.find(f => f.padrao);
			this.filtro.carteiraId = padrao.carteiraId;

            this.filtrarUsuario();
        });
    }

    filtrarUsuario() {
        this.filtrar.emit(this.filtro);
    }

    limpar() {
        this.filtro = {};
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro.carteiraId = padrao.carteiraId;
    }
}
