import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { environment } from "../../../environments/environment";
import { StorageProxy } from "../../modules/common/service/storage-proxy.service";
import { Router } from "@angular/router";
import { User } from "../../modules/autenticacao/model/user";
import { AutenticacaoService } from "../../modules/common/service/autenticacao.service";
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { Notificacao, NotificacaoTipo } from "../../modules/common/model/notificacao";
import { NotificacaoService } from "../../modules/common/service/notificacao.service";
import { FiltroNotificacao } from "../../modules/common/model/filtro-notificacao";
import { MatDialog, MatMenu, MatMenuTrigger } from "@angular/material";
import { DetalheNotificacaoComponent } from "../../modules/notificacao/detalhe-notificacao/detalhe-notificacao.component";
import { Injectable, Inject } from '@angular/core';
import { WINDOW } from "../../common/providers/window.provider";
@Component({
  selector: "my-app-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
  providers: [
    { useClass: NotificacaoService, provide: NotificacaoService }
  ]
})

export class AppHeaderComponent implements OnInit {
  public AppConfig: any;
  public usuario: User;
  public notificacao: Array<Notificacao> = new Array<Notificacao>();
  public notificacaoTipo = NotificacaoTipo;
  public novaNotificacao: boolean = false;
  public _hubConnection: HubConnection | undefined;
  public notificacaoTipoIcone: any = { "error": "cancel", "warning": "warning", "info": "info" };
  logo: string = 'talkmaestro'

  @ViewChild(MatMenu, { static: true }) appNotification: MatMenu;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @Output() menuClosed: EventEmitter<void>;

  constructor(private router: Router,
    private dialog: MatDialog,
    private autenticacaoService: AutenticacaoService,
    private notificacaoService: NotificacaoService,
    @Inject(WINDOW) private window: Window
  ) {

    if (this.window.location.hostname.includes('.teajudei'))
      this.logo = 'teajudei';

    if (this.window.location.hostname.includes('.epbx'))
      this.logo = 'talkmaestro';

    if (this.window.location.hostname.includes('.brbots'))
      this.logo = 'brbots';
  }

  ngOnInit() {
    this.AppConfig = environment;
    this.usuario = StorageProxy.userData.User;


    this.notificacaoService.obterNotificacao(new FiltroNotificacao()).subscribe(notificacao => {
      this.notificacao = notificacao;
    });

    this.conectarSignalR();
    this.escutarSignalR();
    this.reconectarSignalR();
  }

  sair() {
    this.autenticacaoService.logoutUser().subscribe();
    this.router.navigate(["/login"]);
  }

  toggleCollapsedNav() {
    this.AppConfig.navCollapsed = !this.AppConfig.navCollapsed;
  }

  conectarSignalR() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.serverUrl}/notificacao`)
      .configureLogging(signalR.LogLevel.Information)
      .build();
  }

  reconectarSignalR() {
    this._hubConnection.onclose(() => {
      this.conectarSignalR();
      this.escutarSignalR();
      this.reconectarSignalR();
    });
  }

  escutarSignalR() {
    return this._hubConnection.start().then(() => {
      this._hubConnection.on('NotificacaoMiner', notificacao => {
        if (!notificacao) return;

        this.adicionarNotificacao(notificacao);
      });
    });
  }

  pararEscutarSignalR() {
    this._hubConnection.stop();
  }

  adicionarNotificacao(novaNotificacao: Notificacao) {
    if (this.possuiPermissaoNotificacao(novaNotificacao)) {
      novaNotificacao.novo = true;
      this.notificacao.unshift(novaNotificacao);

      if (this.notificacao.length > 10)
        this.notificacao = this.notificacao.slice(0, this.notificacao.length - 1);

      this.novaNotificacao = true;
    }
  }

  marcarNotificacaoLida() {
    this.novaNotificacao = false;
  }

  possuiPermissaoNotificacao(notificacao: Notificacao): boolean {
    var carteiras = StorageProxy.carteiras;
    var listas = StorageProxy.listas;
    var permissaoLista = listas.length == 0 || notificacao.listaId == null || listas.some(l => l.listaId == notificacao.listaId);
    var permissaoCarteira = carteiras.some(c => c.carteiraId == notificacao.carteiraId);

    return permissaoLista && permissaoCarteira;
  }

  onMenuNotificacaoFechado() {
    this.notificacao.forEach((n: Notificacao) => n.novo = false);
  }

  visualizarNotificacao(notificacao: Notificacao) {
    if (notificacao.redirecionaClique) {
      this.router.navigate([`/app/${notificacao.rotaClique}`]);
    }
    else {
      this.dialog.open(DetalheNotificacaoComponent, {
        hasBackdrop: true,
        width: "50%",
        data: {
          'notificacao': notificacao
        }
      });
    }
  }
}