import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit,Inject } from "@angular/core";
import { UsuarioService } from "../../common/service/usuario.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import * as validate from "validate.js";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-alterar-senha",
  templateUrl: "./alterar-senha.component.html",
  providers: [
    { useClass: CustomAlertService, provide: CustomAlertService },
    { useClass: UsuarioService, provide: UsuarioService }
  ]
})
export class AlterarSenhaComponent implements OnInit {
  public login: string;
  private chave: string;
  public senha: string;
  public email: string;
  public confSenha: string;
  public error:any;
  
  constructor(
    private customAlertService: CustomAlertService,
    private usuarioService: UsuarioService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AlterarSenhaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) 
  {
    this.login = data.data.login;
    this.chave = data.chave;
  }

  ngOnInit() {
    
  }

  alterarSenha() {    
    if(this.validate()){
      let objAlteracao= {
        chave: this.chave,
        senha:this.senha
      }

      // this.error=null;
      // this.usuario = new Usuario();
      // this.usuario.email= this.email;
      this.usuarioService.alterarSenha(objAlteracao).subscribe(s=>
        {
          if(s.isValid){
            this.dialogRef.close();
            this.customAlertService.show(
              "Login",
              "login.senhaAlterada",
              "success"
            );
          }
          else{
            this.customAlertService.show(
              "Login",
              "login.autenticacaoFalhou",
              "error"
            );
          }
        }
      );
    }
    else{
      return;
    }
  }



  validate() {
    this.error=[];
    if(!this.senha || this.senha.trim()==""){
      this.error.senha="invalido";
      return false;
    }

    if(!this.confSenha || this.confSenha.trim()==""){
      this.error.confSenha="invalido";
      return false;
    }
    
    if(this.senha != this.confSenha){
        this.error.confSenhaInvalida="invalido";
        return false;
    }
    
    return true;
  }
}
