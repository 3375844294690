import swal from "sweetalert2";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatTabChangeEvent } from "@angular/material";
import { forkJoin } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { UsuarioService } from "../../common/service/usuario.service";
import { ProviderService } from "../../common/service/provider.service";
import { ListaService } from "../../common/service/lista.service";
import { CarteiraService } from "../../common/service/carteira.service";
import { DefaultValuesService } from "../../common/service/default-values.service";

import { Usuario, TipoUsuario } from "../../common/model/usuario";
import { Carteira } from "../../common/model/carteira";
import { CarteiraUsuario } from "../../common/model/carteira-usuario";
import { Lista } from "../../common/model/lista";
import { UsuarioPermissaoLista } from "../../common/model/usuario-permissao-lista";
import { isNull, isObjectEmpty } from "../../common/utils";
import { FiltroLista } from "../../common/model/filtro-lista";

@Component({
	selector: "app-form-usuario",
	templateUrl: "./form-usuario.component.html",
	styleUrls: ["./form-usuario.component.scss", "../style.scss"],
	providers: [
		{ useClass: UsuarioService, provide: UsuarioService },
		{ useClass: ProviderService, provide: ProviderService },
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CarteiraService, provide: CarteiraService },
		{ useClass: DefaultValuesService, provide: DefaultValuesService }
	]
})
export class FormUsuarioComponent {
	usuario: Usuario;
	submited: boolean;
	isEdit: boolean = false;
	possuiLista: boolean = true;
	trocarSenha: boolean = false;
	tipoUsuario: Array<{ label: string; value: number }> = [];

	listasPossiveis: Array<any> = [];
	usuarioPermissaoLista = [];

	carteiras: Carteira[] = [];
	carteirasSource: CarteiraUsuario[] = [];
	carteirasTarget: CarteiraUsuario[] = [];

	listas: Lista[] = [];
	listasSource: UsuarioPermissaoLista[] = [];
	listasTarget: UsuarioPermissaoLista[] = [];
	listasToDisabled: UsuarioPermissaoLista[] = [];

	tabSelected: number = 0;

	public get error() {
		if (this.submited) return this.usuario.validate();
	}

	public liveError(property: string) {
		let validationResult = this.usuario.validate();
		if (!validationResult) return null;
		return validationResult[property] ? validationResult[property][0] : null;
	}

	get permiteAlterarPermissoes(): boolean {
		return (
			this.usuario.usuarioId == 1 ||
			this.usuario.usuarioTipoId == TipoUsuario.Administrador ||
			this.usuario.usuarioTipoId == TipoUsuario.Operador
		);
	}

	constructor(
		public dialog: MatDialog,
		private usuarioService: UsuarioService,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private listaService: ListaService,
		private carteiraService: CarteiraService,
		private defaultValuesServices: DefaultValuesService
	) {

		this.usuario = new Usuario();
		this.usuario.usuarioTipoId = 4;

		this.carregarDados();
	}

	//#region [ Carregamento ]

	carregarDados() {
		this.defaultValuesServices.obterTipoUsuario().then(tipoUsuario => this.tipoUsuario = tipoUsuario.filter(c => c.value == 1 || c.value == 4));

		this.route.params.subscribe(params => {
			if (!params.id) {
				this.carteiraService.obterCarteiras().subscribe(carteiras => {
					this.carteiras = carteiras;
					this.carteirasSource = this.carteiras.map(m => CarteiraUsuario.fromRaw({
						'carteiraUsuarioId': 0,
						'carteiraId': m.carteiraId,
						'usuarioId': 0,
						'dataCadastro': null,
						'ativo': false,
						'nome': m.nome
					}));
				});

				this.listaService.obterLista(new FiltroLista(false, null, true)).subscribe(listas => {
					this.listas = listas;
					this.listasSource = this.listas.map(m => UsuarioPermissaoLista.fromRaw({
						'usuarioPermissaoListaId': 0,
						'usuarioId': 0,
						'listaId': m.listaId,
						'dataCadastro': null,
						'ativo': false,
						'nome': m.nome
					}));
				});
			} else {
				this.isEdit = true;
				let p0 = this.usuarioService.obterUsuario(params.id);
				let p1 = this.carteiraService.obterCarteiras();
				let p2 = this.listaService.obterListaIgnorandoPermissao(new FiltroLista(false, params.id, true));

				forkJoin([p0, p1, p2]).subscribe((results: any) => {
					this.usuario = results[0];
					this.carteiras = results[1];
					this.listas = results[2];

					this.dadosCarteiras();
					this.dadosUsuarios();
				});
			}
		});
	}

	dadosCarteiras() {
		if (!this.usuario.carteiraUsuario)
			this.usuario.carteiraUsuario = [];

		let carteiraUsuario = this.usuario.carteiraUsuario.filter(f => f.ativo);

		this.carteirasSource = this.carteiras.filter(f => carteiraUsuario.some(s => s.carteiraId == f.carteiraId) == false)
			.map(m => {

				let retorno = CarteiraUsuario.fromRaw({
					'carteiraUsuarioId': 0,
					'carteiraId': m.carteiraId,
					'usuarioId': this.usuario.usuarioId,
					'dataCadastro': null,
					'ativo': false,
					'nome': m.nome
				});

				let desabilitado = this.usuario.carteiraUsuario.find(i => i.carteiraId == m.carteiraId);

				if (desabilitado) {
					retorno.carteiraUsuarioId = desabilitado.carteiraUsuarioId;
					retorno.usuarioId = this.usuario.usuarioId;
					retorno.dataCadastro = desabilitado.dataCadastro;
				}

				return retorno;
			});

		this.carteirasTarget = carteiraUsuario;
	}

	dadosUsuarios() {
		if (!this.usuario.carteiraUsuario)
			this.usuario.carteiraUsuario = [];

		let listaPermitidas = this.listas.filter((l: Lista) => this.carteirasTarget.some((s: CarteiraUsuario) => s.carteiraId == l.carteiraId)).map((m: Lista) => m.listaId);
		let listaNaoPermitidas = this.listas.filter((l: Lista) => this.carteirasTarget.some((s: CarteiraUsuario) => s.carteiraId == l.carteiraId) == false).map((m: Lista) => m.listaId);

		let usuarioPermissaoLista = this.usuario.usuarioPermissaoLista.filter(f => f.ativo && listaPermitidas.some((s: number) => s == f.listaId));

		this.listasSource = this.listas.filter(f => usuarioPermissaoLista.some(s => s.listaId == f.listaId) == false && listaPermitidas.some((s: number) => s == f.listaId))
			.map(m => {

				let retorno = UsuarioPermissaoLista.fromRaw({
					'usuarioPermissaoListaId': 0,
					'usuarioId': this.usuario.usuarioId,
					'listaId': m.listaId,
					'dataCadastro': null,
					'ativo': false,
					'nome': m.nome
				});

				let desabilitado = this.usuario.usuarioPermissaoLista.find(i => i.listaId == m.listaId);

				if (desabilitado) {
					retorno.usuarioPermissaoListaId = desabilitado.usuarioPermissaoListaId;
					retorno.usuarioId = this.usuario.usuarioId;
					retorno.dataCadastro = desabilitado.dataCadastro;
				}

				return retorno;
			});

		this.listasTarget = usuarioPermissaoLista;

		this.listasToDisabled = this.listas.filter(f => this.usuario.usuarioPermissaoLista.some(s => s.listaId == f.listaId) && listaNaoPermitidas.some((s: number) => s == f.listaId))
			.map(m => {
				let retorno = UsuarioPermissaoLista.fromRaw({
					'usuarioPermissaoListaId': 0,
					'usuarioId': this.usuario.usuarioId,
					'listaId': m.listaId,
					'dataCadastro': null,
					'ativo': false,
					'nome': m.nome
				});

				let desabilitado = this.usuario.usuarioPermissaoLista.find(i => i.listaId == m.listaId);

				if (desabilitado) {
					retorno.usuarioPermissaoListaId = desabilitado.usuarioPermissaoListaId;
					retorno.usuarioId = this.usuario.usuarioId;
					retorno.dataCadastro = desabilitado.dataCadastro;
				}

				return retorno;
			});

		if (!isObjectEmpty(this.listasToDisabled)) {
			this.usuario.usuarioPermissaoLista.forEach(f => {
				if (this.listasToDisabled.some(s => s.listaId == f.listaId))
					f.ativo = false;
			});
		}
	}

	//#endregion

	//#region [ Tab Actions ]

	disabledTab(index: number) {
		if (this.isEdit)
			return false;

		return !(this.tabSelected >= index);
	}

	tabChange(e: MatTabChangeEvent) {
		this.tabSelected = e.index;
	}

	next() {
		// if (this.isEdit)
		// 	return;

		if (this.tabSelected >= 0 && this.tabSelected < 3)
			this.tabSelected++;

		this.translate
			.get(["telaUsuario.usuario", "telaPadrao.camposInvalidos"])
			.subscribe((res: Object) => {
				this.submited = true;
				this.usuario.validarSenha = !this.isEdit || this.trocarSenha;
				let valid = this.usuario.validate();

				if (valid) {
					swal.fire(res["telaUsuario.usuario"], res["telaPadrao.camposInvalidos"], "error");
					let k = Object.keys(valid);
					if (k) this.tabSelected = this.usuario.campoTab[k[0]];
					return;
				}
			});
	}

	previous() {
		if (this.tabSelected > 0 && this.tabSelected <= 3)
			this.tabSelected--;
	}

	//#endregion

	//#region [ PickList - Events ]

	moveCarteiraToTarget(e: any) {
		e.items = e.items.map((m: CarteiraUsuario) => {
			m.ativo = true;
			return m;
		});
		this.dadosUsuarios();
	}

	moveCarteiraToSource(e: any) {
		e.items = e.items.map((m: CarteiraUsuario) => {
			m.ativo = false;
			return m;
		});
		this.dadosUsuarios();
	}

	moveListaToTarget(e: any) {
		e.items = e.items.map((m: UsuarioPermissaoLista) => {
			m.ativo = true;
			return m;
		});
	}

	moveListaToSource(e: any) {
		e.items = e.items.map((m: UsuarioPermissaoLista) => {
			m.ativo = false;
			return m;
		});
	}

	//#endregion

	//#region [ Salvar ]

	salvar() {
		this.submited = true;
		this.usuario.validarSenha = !this.isEdit || this.trocarSenha;

		this.translate
			.get(["telaUsuario.usuario", "telaPadrao.camposInvalidos", "telaPadrao.sucessoSalvar", "telaPadrao.erroSalvar"])
			.subscribe((res: Object) => {

				let carteiraUsuario: CarteiraUsuario[] = [];
				this.carteirasTarget.forEach((f: CarteiraUsuario) => carteiraUsuario.push(f));
				this.carteirasSource.filter((f: CarteiraUsuario) => f.carteiraUsuarioId > 0).forEach((f: CarteiraUsuario) => carteiraUsuario.push(f));
				this.usuario.carteiraUsuario = carteiraUsuario;

				let usuarioPermissaoLista: UsuarioPermissaoLista[] = [];
				this.listasTarget.forEach((f: UsuarioPermissaoLista) => usuarioPermissaoLista.push(f));
				this.listasSource.filter((f: UsuarioPermissaoLista) => f.usuarioPermissaoListaId > 0).forEach((f: UsuarioPermissaoLista) => usuarioPermissaoLista.push(f));
				this.listasToDisabled.filter((f: UsuarioPermissaoLista) => f.usuarioPermissaoListaId > 0).forEach((f: UsuarioPermissaoLista) => usuarioPermissaoLista.push(f));
				
                this.usuario.usuarioPermissaoLista = usuarioPermissaoLista;

				let valid = this.usuario.validate();
				if (valid) {
					swal.fire(res["telaUsuario.usuario"], res["telaPadrao.camposInvalidos"], "error");

					let key = Object.keys(valid);
					if (key) this.tabSelected = (this.usuario.campoTab[key[0]]) ? this.usuario.campoTab[key[0]] : 0;
					return;
				}

				const sucesso = () => {
					swal.fire(res["telaUsuario.usuario"], res["telaPadrao.sucessoSalvar"], "success");
					this.router.navigate(["/app/usuario"]);
				};

				const erro = () =>
					swal.fire(res["telaUsuario.usuario"], res["telaPadrao.erroSalvar"], "error");

				if (!this.usuario.usuarioId)
					return this.usuarioService.criar(this.usuario).subscribe(sucesso, erro);

				this.usuarioService.atualizar(this.usuario).subscribe(sucesso, erro);
			});
	}

	//#endregion
}
