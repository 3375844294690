import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { FiltroDashPrincipal } from "../../model/filtro-dash-principal";
import { ListaService } from "../../../common/service/lista.service";
import { FiltroLista } from "../../../common/model/filtro-lista";
import { environment } from "../../../../../environments/environment";
import { CarteiraService } from "../../../common/service/carteira.service";
import { Carteira } from "../../../common/model/carteira";
import { Lista } from "../../../common/model/lista";

@Component({
    selector: "app-filtro-dash",
    templateUrl: "./filtro-dash.component.html",
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: CarteiraService, provide: CarteiraService }
    ]
})
export class FiltroDashComponent implements OnInit {
    filtro: FiltroDashPrincipal;
    cardState: string = "in";
    @Output()
    search: EventEmitter<FiltroDashPrincipal>;
    interval: NodeJS.Timer;
    dirty: boolean;
    carteiras: Array<Carteira> = [];
    listas: Array<Lista> = [];
    
    public AppConfig: any;

    public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1 || this.filtro.carteira.length == 1);
	}

    constructor(
        private listaService: ListaService,
		private carteiraService: CarteiraService
    ) {
        this.filtro = new FiltroDashPrincipal();
        this.search = new EventEmitter<FiltroDashPrincipal>();
    }

    public get erros() {
        if (!this.dirty) return;
        return this.filtro.validar();
    }

    ngOnInit() {
        let filtroLista = new FiltroLista();
        filtroLista.ignorarErroPesquisa = true;
        filtroLista.ignorarIndisponivel = true;

        this.listaService
            .obterLista(filtroLista)
            .subscribe(lista => {
                this.listas = lista;
                this.filtro.listaPossivel = lista;
            });
        this.emit();
        this.AppConfig = environment;

        this.obterCarteiras();
    }

    obterCarteiras() {
		this.carteiraService.obterCarteiras().subscribe(carteiras => {
				this.carteiras = carteiras;
                this.filtro.carteira = carteiras;

				let padrao = this.carteiras.find(f => f.padrao);
				this.filtro.carteiraId = padrao.carteiraId;

                this.carteiraChange();
				this.emit();
			});
	}

    carteiraChange() {
        this.filtro.carteiraId = Number(this.filtro.carteiraId);
        this.obterListas();
    }

    obterListas() {
        let filtroLista = new FiltroLista();
        filtroLista.ignorarErroPesquisa = true;
        filtroLista.ignorarIndisponivel = true;

        if(this.filtro.carteiraId > 0)
            filtroLista.carteiraId = this.filtro.carteiraId;

		this.listaService.obterListaSemQuantidade(filtroLista).subscribe(listas => (this.filtro.listaPossivel = listas));

        this.emit();
	}

    startAutoRefresh(event) {
        if (event.checked) this.interval = setInterval(this.emit, 15 * 1000 * 60);
        else clearInterval(this.interval);
    }

    emit() {
        this.dirty = true;

        if (this.filtro.validar()) return;

        this.search.emit(this.filtro);
        this.cardState = "out";
    }

    clean() {
        this.filtro = new FiltroDashPrincipal();
        this.filtro.listaPossivel = this.listas;
        this.filtro.carteira = this.carteiras;
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro.carteiraId = padrao.carteiraId;
        this.search.emit(this.filtro);
    }

    openReportPaneas() {
        const url = 'https://bi.talktelecom.net/reports/bradesco/rel_miner.aspx';
        window.open(url, '_blank');
    }
}
