import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { Provider } from "../../common/model/provider";
import { HistoricoRetornoService } from "../../common/service/historico-retorno.service";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { PreviewRetornoModalComponent } from "./preview-retorno-modal/preview-retorno-modal.component";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import * as signalR from "@aspnet/signalr";
import { environment } from "../../../../environments/environment";
import { FiltroHistoricoRetorno, FiltroHistoricoRetornoCamposValor } from "../../common/model/filtro-historico-retorno";
import { ConfiguracaoCampoProvider, ProvedorConfiguracaoTipo } from "../../common/model/configuracao-campo-provedor";
import { ProviderService } from "../../common/service/provider.service";
import { isNull, isNullOrZero } from "../../common/utils";
import { Estrategia } from "../../common/model/estrategia";
import { Carteira } from "../../common/model/carteira";
import { CarteiraService } from "../../common/service/carteira.service";

@Component({
    selector: "app-historico-retorno",
    templateUrl: "./historico-retorno.component.html",
    styleUrls: ["./historico-retorno.component.scss"],
    providers: [
        { useClass: HistoricoRetornoService, provide: HistoricoRetornoService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: CarteiraService, provide: CarteiraService }
    ]
})

export class HistoricoRetornoComponent implements OnInit {
    isDirty: boolean;
    filtro: FiltroHistoricoRetorno = new FiltroHistoricoRetorno();;
    todosProvedoresRetorno: Array<Provider>;
    provedoresRetorno: Array<Provider>;
    provedoresLista: Array<any>;
    listasRetornoGeral: Array<any>;
    listasRetornoProvedor: Array<any>;
    estrategias: Array<Estrategia> = [];
    estrategiasRetornoGeral: Array<any>;
    estrategiasRetornoProvedor: Array<any>;
    carteiras: Array<Carteira> = [];

    retornos: Array<any>;
    status: Array<any> = [{ value: "false", label: "Pendente" }, { value: "true", label: "Processado" }];
    dataHoraProximaExecucao: Date;
    dataHoraInicioExecucao: Date;
    atualizandoExecucao: boolean;

    camposRetornoProvedor: Array<ConfiguracaoCampoProvider>;
    campoNome: string;
    campoValor: string;

    private _hubConnection: HubConnection | undefined;

    public get erros() {
        if (!this.isDirty)
            return false;

        return this.filtro.validate();
    }

    public get disabledCarteira(): boolean {
        return (this.carteiras.length == 1);
    }

    constructor(
        private historicoRetornoService: HistoricoRetornoService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private customAlertService: CustomAlertService,
        private estrategiaService: EstrategiaService,
        private providerService: ProviderService,
        private carteiraService: CarteiraService,
    ) {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(`${environment.serverUrl}/notificacao`)
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.setarFiltroPadrao();
        this.obterCarteiras();
        this.popular(false);
    }

    ngOnInit() {
        this._hubConnection.start().then(() => {
            this._hubConnection.on("AtualizarHistoricoRetorno", (dados: any) => {

                if (dados.providerId == this.filtro.providerId && this.isDirty) {
                    if (dados.status == 0) {
                        this.atualizandoExecucao = true;
                        this.dataHoraProximaExecucao = dados.dataHoraProximaExecucao;
                        this.dataHoraInicioExecucao = dados.dataHoraInicioExecucao;
                    } else {
                        this.dataHoraInicioExecucao = null;
                    }

                    setTimeout(() => {
                        this.atualizandoExecucao = false;
                    }, 1000);
                }
            });
        });
    }

    pesquisar() {
        this.dataHoraInicioExecucao = null;
        this.dataHoraProximaExecucao = null;
        this.isDirty = true;

        if (this.erros)
            return this.customAlertService.show(
                "menu.historicoRetorno",
                this.erros["providerId"] && this.erros["listaId"] ? 'telaHistoricoRetorno.campoProvedorListaObrigatorio' : 'telaPadrao.camposInvalidos',
                "error"
            );


        if (!isNullOrZero(this.filtro.providerId)) {
            this.historicoRetornoService.obterProximaExecucao(this.filtro.providerId).subscribe(agendamento => {
                this.dataHoraProximaExecucao = agendamento.proximaExecucao;
            });
        }

        this.historicoRetornoService.obterHistoricoRetorno(this.filtro).subscribe(retornos => {
            this.retornos = retornos;
        })
    }

    visualizarRetorno(retorno: any) {
        this.dialog.open(PreviewRetornoModalComponent, {
            hasBackdrop: true,
            width: "70%",
            height: "90%",
            data: { 'retorno': retorno }
        });
    }

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras;

            let padrao = this.carteiras.find(f => f.padrao);
            this.filtro.carteiraId = padrao.carteiraId;
        });
    }

    popular(FILTRO: boolean) {

        let obterProvedoresListaRetorno = {
            'carteiraId': 0
        };
        let obterEstrategias = {
            'comEstrategiasFilhas': true,
            'carteiraId': 0
        };
        let carteiraId = this.filtro.carteiraId == undefined ? 0 : this.filtro.carteiraId;

        if(FILTRO) {
            obterProvedoresListaRetorno = {
                'carteiraId': this.filtro.carteiraId
            };
            obterEstrategias = {
                'comEstrategiasFilhas': true,
                'carteiraId': this.filtro.carteiraId
            };
        }

        this.historicoRetornoService.obterProvedoresListaRetorno(obterProvedoresListaRetorno).subscribe(provedores => {
            this.provedoresLista = provedores;
            this.listasRetornoGeral = provedores.filter((a, b, arr) => { return arr.indexOf(arr.find(t => t.listaId === a.listaId)) === b })
                .map(l => ({ listaId: l.listaId, nome: l.nomeLista }));
            this.listasRetornoGeral = this.listasRetornoGeral;
            this.listasRetornoProvedor = [... this.listasRetornoGeral];
        });

        this.estrategiaService.obterEstrategias(obterEstrategias).subscribe(estrategias => {
            this.estrategiasRetornoProvedor = estrategias;
            this.estrategiasRetornoGeral = estrategias.filter((f) => f.ativo == true)
                .map(l => ({
                    'listaEstrategiaId': l.listaEstrategiaId.toString(), 'nome': l.nome, 'providerId': l.providerId, 'listaId': l.listaId,
                    'displayNomeLista': l.displayNomeLista, 'estrategiasFilhas': l.estrategiasFilhas
                }));
            this.estrategiasRetornoGeral = this.estrategiasRetornoGeral;
            this.estrategiasRetornoProvedor = [... this.estrategiasRetornoGeral];
        });

        this.historicoRetornoService.obterProvedoresRetorno(carteiraId).subscribe(provedores => {
            this.todosProvedoresRetorno = provedores;
            this.provedoresRetorno = provedores;

            this.route.params.subscribe(params => {
                if (!params.id)
                    return;

                this.filtro.providerId = parseInt(params.id);
                this.pesquisar();
            });
        });
    }

    //#region [ Métodos Filtro ]

    setarFiltroPadrao() {
        let dataFim = new Date();
        let dataInicio = new Date(dataFim.getFullYear(), dataFim.getMonth(), dataFim.getDate() - 5);

        this.filtro = new FiltroHistoricoRetorno();
        this.campoValor = null;
        this.filtro.dataInicio = dataInicio;
        this.filtro.dataFim = dataFim;
        this.filtro.processado = null;

        if(this.carteiras.length > 0) {
            let padrao = this.carteiras.find(f => f.padrao);
            this.filtro.carteiraId = padrao.carteiraId;
        }
    }

    limpar() {
        this.setarFiltroPadrao();

        this.provedoresRetorno = this.todosProvedoresRetorno;
        this.listasRetornoProvedor = this.listasRetornoGeral;
        this.estrategiasRetornoGeral = this.estrategiasRetornoProvedor;
    }

    adicionarCampo() {
        if (this.campoNome == null)
            return;

        this.filtro.camposValor.push(new FiltroHistoricoRetornoCamposValor(this.campoNome, this.campoValor));
        this.campoNome = null;
        this.campoValor = null;
    }

    removerCampo(campoFiltro: FiltroHistoricoRetornoCamposValor) {
        this.filtro.camposValor = this.filtro.camposValor.filter(f => f != campoFiltro);
    }

    //#endregion

    //#endregion [ Métodos Changed Filtro ]

    carteiraChange() {
		this.popular(true);
	}

    provedorSelecionadoChanged() {
        this.filtro.camposValor = new Array<FiltroHistoricoRetornoCamposValor>();
        this.campoNome = null;

        if (this.filtro.providerId != null) {
            this.listasRetornoProvedor = this.provedoresLista.filter((f: any) => f.providerId.toString() == this.filtro.providerId)
                .map(l => ({ 'listaId': l.listaId.toString(), 'nome': l.nomeLista }));

            this.estrategiasRetornoGeral = this.estrategiasRetornoProvedor.filter((f: any) => f.providerId == this.filtro.providerId)
                .map(l => ({ 'listaEstrategiaId': l.listaEstrategiaId.toString(), 'nome': l.nome }));

            this.providerService.obterProviderComCampos(this.filtro.providerId).subscribe(provedor => {
                this.camposRetornoProvedor = provedor.configuracaoCampoProvider.filter(c => c.ativo && c.provedorConfiguracaoTipoId == ProvedorConfiguracaoTipo.Retorno);
            });
        }
        else {
            this.camposRetornoProvedor = null;
            this.listasRetornoProvedor = [...this.listasRetornoGeral];
            this.estrategiasRetornoGeral = [...this.estrategiasRetornoProvedor];
        }
    }

    listaRetornoSelecionadoChanged() {
        this.filtro.camposValor = new Array<FiltroHistoricoRetornoCamposValor>();
        this.campoNome = null;

        if (!isNull(this.filtro.listaId)) {
            this.estrategiasRetornoGeral = this.estrategiasRetornoProvedor.filter((f: any) => f.listaId == this.filtro.listaId)
                .map(l => ({ 'listaEstrategiaId': l.listaEstrategiaId, 'nome': l.nome }));

            let idsProvedor = this.provedoresLista.filter((f: any) => f.listaId == this.filtro.listaId)
                .map((l: any) => l.providerId);
            this.provedoresRetorno = this.todosProvedoresRetorno.filter((f: Provider) => idsProvedor.some((s: any) => s === f.providerId));

            this.filtro.processado = true;
        }
        else {
            this.provedoresRetorno = this.todosProvedoresRetorno;
            this.listasRetornoProvedor = this.listasRetornoGeral;
            this.estrategiasRetornoGeral = this.estrategiasRetornoProvedor;
            this.filtro.processado = null;
        }
    }

    estrategiaRetornoSelecionadoChanged() {
        this.filtro.camposValor = new Array<FiltroHistoricoRetornoCamposValor>();
        this.campoNome = null;

        if (!isNull(this.filtro.listaEstrategiaId)) {
            this.listasRetornoProvedor = this.estrategiasRetornoGeral.filter((f: any) => f.listaEstrategiaId == this.filtro.listaEstrategiaId)
                .map(l => ({ 'listaId': l.listaId.toString(), 'nome': l.displayNomeLista }));

            let idsProvedor: Array<any> = [];
            let estrategiaSelecionada = this.estrategiasRetornoGeral.find(e => e.listaEstrategiaId == this.filtro.listaEstrategiaId);

            idsProvedor.push(estrategiaSelecionada.providerId);
            if (estrategiaSelecionada.estrategiasFilhas != null) idsProvedor.push(...estrategiaSelecionada.estrategiasFilhas.map(f => f.providerId))

            this.provedoresRetorno = this.todosProvedoresRetorno.filter(f => idsProvedor.find(s => s == f.providerId));

            this.filtro.processado = true;
        }
        else {
            this.provedoresRetorno = this.todosProvedoresRetorno;
            this.estrategiasRetornoGeral = this.estrategiasRetornoProvedor;
            this.listasRetornoProvedor = this.listasRetornoGeral;
            this.filtro.processado = null;
        }
    }

    //#endregion
}