import { disableDebugTools } from "@angular/platform-browser";

export enum OperacaoAgrecacao {
	unique = 0,
	average = 1,
	max = 2,
	min = 3,
	sum = 4,
	cardinal = 5,
	cardinalBy = 6
}

export function obterOperacaoAgregacaoMapeada(tipoCampo: string) {
	var operacaoAgregacao = Object.keys(OperacaoAgrecacao)
		.filter((c: any) => isNaN(c) === true)
		.map(c => ({ label: c, value: OperacaoAgrecacao[c] }));

	if (tipoCampo == "Texto")
		return operacaoAgregacao.filter(c => c.value == OperacaoAgrecacao.cardinal);

	if (tipoCampo == "Inteiro")
		return operacaoAgregacao.filter(c => c.value != OperacaoAgrecacao.unique);

	if (tipoCampo == "Decimal")
		return operacaoAgregacao.filter(c => c.value != OperacaoAgrecacao.unique);

	if (tipoCampo == "Long")
		return operacaoAgregacao.filter(c => c.value != OperacaoAgrecacao.unique);

	if (tipoCampo == "Data")
		return operacaoAgregacao.filter(c => [OperacaoAgrecacao.max, OperacaoAgrecacao.min].includes(c.value));

	if (tipoCampo == "Lista")
		return operacaoAgregacao.filter(c => [OperacaoAgrecacao.unique].includes(c.value));

	return [];
}
