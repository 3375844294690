import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { LoginComponent } from "./login/login.component";
import { EsqueciSenhaComponent } from "./esqueci-senha/esqueci-senha.component";
import { AlterarSenhaComponent } from "./alterar-senha/alterar-senha.component";

import {
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material";
import { AutenticacaoService } from "../common/service/autenticacao.service";
import { AppCommonModule } from "../common/common.module";
import { AcessoNegadoComponent } from "./acesso-negado/acesso-negado.component";
import { environment } from "../../../environments/environment";

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    RouterModule,
    MatIconModule,
    AppCommonModule
  ],
  entryComponents: [
    EsqueciSenhaComponent,
    AlterarSenhaComponent
  ],
  providers: [
    { useClass: AutenticacaoService, provide: AutenticacaoService },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` }}
  ],
  declarations: [LoginComponent, AcessoNegadoComponent,EsqueciSenhaComponent,AlterarSenhaComponent]
})
export class AutenticacaoModule {}
