import { Component, Output, EventEmitter } from "@angular/core";
import { DefaultValuesService } from "../../../common/service/default-values.service";
import { CarteiraService } from "../../../common/service/carteira.service";
import { Carteira } from "../../../common/model/carteira";

@Component({
	selector: "app-filtro-template",
	templateUrl: "./filtro-template.component.html",
	providers: [
		{ useClass: CarteiraService, provide: CarteiraService },
		{ useClass: DefaultValuesService, provide: DefaultValuesService }
	]
})
export class FiltroTemplateComponent {
	filtro: any = {};
	carteiras: Array<Carteira> = [];
	
	listaBoolean: { label: any; value: boolean }[] = [];
	listaTipoTemplate: { label: any; value: number }[] = [];

	@Output()
	filtrar: EventEmitter<any> = new EventEmitter<any>();

	public get disabledCarteira(): boolean {
		return (this.carteiras.length == 1);
	}

	constructor(private carteiraService: CarteiraService, private defaultValuesService: DefaultValuesService) {
		this.carteiraService.obterCarteiras().subscribe(carteiras => {
				this.carteiras = carteiras;

				let padrao = this.carteiras.find(f => f.padrao)
				this.filtro.carteiraId = padrao.carteiraId;

                this.filtrarTemplate();
			});

		this.defaultValuesService
			.obterBoolean()
			.then(lista => (this.listaBoolean = lista));

		this.defaultValuesService
			.obterTipoTemplate()
			.then(listaTipoTemplate => (this.listaTipoTemplate = listaTipoTemplate));
	}

    carteiraChange() {
        this.filtrarTemplate();
    }

	filtrarTemplate() {
		this.filtrar.emit(this.filtro);
	}

	limpar() {
		this.filtro = {};
        let padrao = this.carteiras.find(f => f.padrao);
        this.filtro.carteiraId = padrao.carteiraId;
	}
}
