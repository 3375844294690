import { Component, Input, OnInit, OnDestroy } from "@angular/core";
// import { MatDialogRef } from "@angular/material";

import { LimpezaFiltroArquivo } from "../../common/model/limpeza-filtro-arquivo";
import { ListaService } from "../../common/service/lista.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { TranslateService } from "@ngx-translate/core";
import { HubConnectionBuilder, HubConnection } from "@aspnet/signalr";
import { isNull, isNullOrEmpty } from "../../common/utils";
import { Template } from "../../common/model/template";
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { AutenticacaoService } from "../../common/service/autenticacao.service";
import { environment } from "../../../../environments/environment";
import * as signalR from "@aspnet/signalr";

@Component({
    selector: "app-limpeza-filtro-arquivo",
    templateUrl: "./limpeza-filtro-arquivo.component.html",
    styleUrls: ["./limpeza-filtro-arquivo.component.scss"],
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class LimpezaFiltroArquivoComponent implements OnInit, OnDestroy {

    //#region [ ListaId ]

    _listaId: number;

    @Input()
    public set listaId(listaId: number) {
        if (!listaId) return;
        this._listaId = listaId;
        this.updateUrlLimpezaFiltroArquivoUpload();
        this.obterLimpezaFiltroArquivo();
    }

    public get listaId() {
        return this._listaId;
    }

    //#endregion 

    //#region [ Query ]

    _query: any;

    @Input()
    public set query(query: any) {
        if (!query) return;
        this._query = query;
    }

    public get query() {
        return this._query;
    }

    //#endregion 

    @BlockUI()
    blockUI: NgBlockUI;

    apiUpload: string;
    exibirMsgArquivo: boolean = true;
    dirty: boolean;
    listaLimpezaFiltroArquivo: Array<LimpezaFiltroArquivo> = [];
    _hubConnection: HubConnection | undefined;

    constructor(
        private authService: AutenticacaoService,
        private listaService: ListaService,
        private customAlertService: CustomAlertService,
        private translate: TranslateService
    ) {
        this.dirty = false;
    }

    ngOnInit() {
        this.conectarSignalR();
        this.escutarSignalR();
        this.reconectarSignalR();
    }

    ngOnDestroy() {
        this._hubConnection.stop();
    }

    conectarSignalR() {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(`${environment.serverUrl}/notificacao`)
            .configureLogging(signalR.LogLevel.Information)
            .build();
    }

    reconectarSignalR() {
        this._hubConnection.onclose(() => {
            this.conectarSignalR();
            this.escutarSignalR();
            this.reconectarSignalR();
        });
    }

    escutarSignalR() {
        return this._hubConnection.start().then(() => {
            this._hubConnection.on('AtualizacaoLimpezaFiltroArquivoTask', (conteudo: any) => {
                if (!conteudo) return;
                this.atualizarLimpezaFiltroArquivoSignalR(conteudo);
            });
        });
    }

    atualizarLimpezaFiltroArquivoSignalR(conteudo: any) {
        if (!isNull(conteudo.limpezaFiltroArquivoId)) {
            if (conteudo.atualizacaoStatus) {
                this.listaLimpezaFiltroArquivo = this.listaLimpezaFiltroArquivo.map((f: LimpezaFiltroArquivo) => {
                    if (f.limpezaFiltroArquivoId == conteudo.limpezaFiltroArquivoId)
                        f = LimpezaFiltroArquivo.fromRaw(conteudo.limpezaFiltroArquivo);

                    return f;
                });
            }

            if (conteudo.atualizacaoPorcentagem) {
                this.listaLimpezaFiltroArquivo = this.listaLimpezaFiltroArquivo.map((f: LimpezaFiltroArquivo) => {

                    if (f.limpezaFiltroArquivoId == conteudo.limpezaFiltroArquivoId) {

                        f = LimpezaFiltroArquivo.fromRaw(conteudo.limpezaFiltroArquivo);

                        if (conteudo.tipoExecucao == 1) {
                            f.tempoExecucaoElastic = conteudo.tempoExecucaoElastic;
                            f.porcentagem = conteudo.porcentagem;
                            f.executados = conteudo.executados;
                            f.restantes = conteudo.restantes;
                        }

                        if (conteudo.tipoExecucao == 2) {
                            f.tempoExecucaoBancoDados = conteudo.tempoExecucaoBancoDados;
                            f.porcentagem = conteudo.porcentagem;
                            f.executados = conteudo.executados;
                            f.restantes = conteudo.restantes;
                        }
                    }

                    return f;
                });
            }
        }
    }

    isExclusaoElasticInicio = (item: LimpezaFiltroArquivo): boolean => (!isNull(item.exclusaoElasticInicio));
    isExclusaoElasticFim = (item: LimpezaFiltroArquivo): boolean => (!isNull(item.exclusaoElasticFim));
    isExclusaoBancoDadosInicio = (item: LimpezaFiltroArquivo): boolean => (!isNull(item.exclusaoBancoDadosInicio));
    isExclusaoBancoDadosFim = (item: LimpezaFiltroArquivo): boolean => (!isNull(item.exclusaoBancoDadosFim));
    isTempoExecucaoElastic = (item: LimpezaFiltroArquivo): boolean => (!isNullOrEmpty(item.tempoExecucaoElastic));
    isTempoExecucaoBancoDados = (item: LimpezaFiltroArquivo): boolean => (!isNull(item.tempoExecucaoBancoDados));

    updateUrlLimpezaFiltroArquivoUpload() {
        this.apiUpload = this.listaService.getUrlLimpezaFiltroArquivoUpload(this.listaId);
    }

    obterLimpezaFiltroArquivo() {
        this.listaService.obterLimpezaFiltroArquivoPorListaId(this.listaId)
            .subscribe((result: Array<LimpezaFiltroArquivo>) => this.listaLimpezaFiltroArquivo = result);
    }

    //#region [ Upload ]

    onBasicUpload($event: any) {
        if (this.blockUI.isActive)
            this.blockUI.stop();

        if ($event.xhr.status === '413') {
            this.customAlertService.show('telaLista.lista', 'telaLista.erroTamanhoUpload', 'error');

        } else {
            if ($event.xhr.type === 0)
                return;

            this.blockUI.stop();

            const response = JSON.parse($event.xhr.responseText);

            if (response.data === false)
                this.customAlertService.show('telaLista.lista', 'telaLista.arquivoRejeitado', 'error');

            this.exibirMsgArquivo = true;
            this.obterLimpezaFiltroArquivo();
        }
    }

    onSelect($event: any) {
        this.exibirMsgArquivo = ($event.files.length === 0);
    }

    onError($event: any) {
        if (this.blockUI.isActive)
            this.blockUI.stop();

        if ($event.xhr.response) {
            if ($event.xhr.statusText === 'Unknown Error')
                this.customAlertService.show('telaLista.lista', 'telaLista.erroTamanhoUpload', 'error');
        }

        this.obterLimpezaFiltroArquivo();
    }

    onBeforeSend($event: any) {
        this.blockUI.start();
        $event.xhr.setRequestHeader('Authorization', 'Bearer ' + this.authService.obterTokenAutenticado());
    }

    //#endregion
}
