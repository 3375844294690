import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from '@angular/material';

import { Estrategia, EstrategiaAcaoCampanhaTipo } from "../../common/model/estrategia";
import { InfoExecucaoEstrategiaComponent } from "./info-execucao-estrategia/info-execucao-estrategia.component";
import { LogHistoricoComponent } from "./../../common/components/log-historico/log-historico.component";
import { EstrategiaService } from "../../common/service/estrategia.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { Router } from "@angular/router";
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import * as primeng from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Lista } from "../../common/model/lista";
import { VisualizarProvedorModalComponent } from "../visualizar-provedor-modal/visualizar-provedor-modal.component";

@Component({
    selector: "app-lista-estrategia",
    templateUrl: "./lista-estrategia.component.html",
    styleUrls: ["./lista-estrategia.component.scss"],
    providers: [
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: CustomAlertService, provide: CustomAlertService }
    ]
})
export class ListaEstrategiaComponent {
    estrategias: Array<Estrategia> = [];
    filtro: any = {};
    travarEscolhaLista: boolean;
    isRotaLista: boolean = false;
    size: number;

    sortField: string = 'tipoRecorrencia';
    sortOrder: number = -1;
    totalRecords: number;
    paginaAtual: any = 0;
    tamanhoAtual: any = 10;
    pesquisou: boolean = false;


    @ViewChild(Table, { static: false }) dataTable: Table;
    constructor(
        private estrategiaService: EstrategiaService,
        private route: ActivatedRoute,
        private customAlertService: CustomAlertService,
        private router: Router,
        public dialog: MatDialog
    ) {
        this.route.params.subscribe(params => {
            this.filtro = (params.listaId != undefined) ? { lista: parseInt(params.listaId) } : {};
            this.travarEscolhaLista = params.listaId != undefined;
            this.isRotaLista = (this.travarEscolhaLista);
        });
    }

    ngOnInit() {
        this.populateFiltro();
        this.populate(false);
    }

    queryBaseTotal: any = {
        size: 10,
        _source: {
            includes: []
        },
        query: {
            bool: {
                must: [],
                must_not: []
            }
        }
    }

    lazyPaginate(event: any) {
        if (!this.pesquisou) {
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            this.paginaAtual = event.first;
            this.tamanhoAtual = event.rows;
        }
        this.pesquisou = false;
    }

    paginate(event: any) {
        this.paginaAtual = event.first;
        this.tamanhoAtual = event.rows;

        this.populateFiltro();
        this.populate(false);
    }

    filtrarEstrategias(filtro: any) {
        this.pesquisou = true;
        this.paginaAtual = 0;
        
        this.populateFiltro();
        this.populate(true);
    }

    populateFiltro() {
        this.filtro.paginar = true;
        this.filtro.paginaAtual = this.paginaAtual;
        this.filtro.tamanhoAtual = this.tamanhoAtual;
        this.filtro.sortField = this.sortField;
        this.filtro.sortOrder = this.sortOrder;
        this.filtro.estrategiaAcaoCampanhaTipos = (this.isRotaLista) ? [EstrategiaAcaoCampanhaTipo.ImportarCampanha, EstrategiaAcaoCampanhaTipo.FluxoImportarCampanha] : [EstrategiaAcaoCampanhaTipo.ImportarCampanha];
    }

    populate(pageZero: boolean) {
        this.estrategiaService.obterEstrategiasPaginadas(this.filtro).subscribe(result => {
            this.estrategias = result.data;
            this.totalRecords = result.total;
            if(pageZero)
                this.setCurrentPage(0);
        });
    }

    setCurrentPage(n: number) {
        if (this.dataTable) {
            this.dataTable.first = n * this.tamanhoAtual;
        }
    }

    ativarEnvioImediato(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarAtivacaoEnvioImediato")
            .then(() =>
                this.estrategiaService
                    .ativarEnvioImediato(id)
                    .subscribe(() => this.filtrarEstrategias(this.filtro))
            );
    }

    ativarPendenteExecucao(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarReativacao")
            .then(() =>
                this.estrategiaService
                    .ativarPendenteExecucao(id)
                    .subscribe(() => this.filtrarEstrategias(this.filtro))
            );
    }

    exibirInfoExecucao(estrategia): void {
        const dialogRef = this.dialog.open(InfoExecucaoEstrategiaComponent, {
            width: '500px',
            data: estrategia
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    forcarExecucao(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.forcarExecucao")
            .then(() =>
                this.estrategiaService
                    .forcarExecucao(id)
                    .subscribe(() => this.filtrarEstrategias(this.filtro))
            );
    }


    excluirEstrategias(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarExclusao")
            .then(() =>
                this.estrategiaService
                    .excluir(id)
                    .subscribe(() => this.filtrarEstrategias(this.filtro))
            );
    }

    duplicarEstrategia(id) {
        this.customAlertService
            .confirmationMessage("telaEstrategia.confirmarDuplicarEstrategia")
            .then(() =>
                this.estrategiaService
                    .duplicarEstrategia(id)
                    .subscribe(model =>
                        this.router.navigate([`/app/estrategia/${model.listaEstrategiaId}`])
                    )
            );
    }

    mostrarPreview(filtro: any, lista: Lista, title: string) {
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: lista.listaTemplateId,
                lista: lista.nome,
                listaId: lista.listaId,
                title: `Encontrados na Estratégia: ${title}`,
                query: JSON.parse(filtro)
            }
        });
    }

    mostrarPreviewTotal(lista: Lista, title: string) {
        this.dialog.open(PreviewDadosEstrategiaComponent, {
            height: "700px",
            width: "80%",
            hasBackdrop: true,
            data: {
                templateId: lista.listaTemplateId,
                lista: lista.nome,
                listaId: lista.listaId,
                title: `Total da Lista: ${title}`,
                query: this.queryBaseTotal
            }
        });
    }

    abrirLogHistorico(id) {

        let data = {
            id: id,
            entidade: 0
        };

        this.dialog.open(LogHistoricoComponent, {
            height: "500px",
            width: "40%",
            data
        });
    }

    visualizarProvedor(estrategia: Estrategia) {
        this.dialog.open(VisualizarProvedorModalComponent, {
            height: "450px",
            width: "50%",
            data: {
                listaEstrategiaId: estrategia.listaEstrategiaId
            }
        });
    }
}
